import React, { useState, useEffect } from 'react';


function Async(props) {

    const [users,setUsers] = useState();

    useEffect(() => {

        fetch('https://jsonplaceholder.typicode.com/todos')
        .then( response => response.json())
        .then( (users) => {

            // console.log('users',users);
            setUsers(users);

        });

        
        
    }, [])

    let render;

    if(users){
        render =  users.map(user => {
            return (<>
                <li>{user.title}</li>
            </>)
        });
    }

    return (
        <>
    
        {
         render
        }
            
        </>
    )
}


export default Async

