import React,{ useState,useEffect } from 'react'

function Numbar(props) {

    const [result, setResult] = useState(0);
    const [number,setNumber] = useState(1);

    const adderHandler = () => {
        setResult( p => number + p );
    }
    const minusHandler = () => {
        setResult( p => number - p );
    }

    const inputChangeHandler = (e) => {
        setNumber(parseInt(e.target.value));
    }

    return (
        <>
            
            <div style={{paddingTop:'100px'}} className="text-center">
            <h2 data-testid="result" className={[(result > 100)? "text-info" : "", (result < 0)? "text-danger" : "" ].join(' ')}>{result}</h2>
           
                <button data-testid="minus-btn" onClick={minusHandler}>-</button>
                <input data-testid="input" type="text" value={number} onChange = { e => inputChangeHandler(e)} />
                <button data-testid="plus-btn" onClick={adderHandler}>+</button>
            </div>
            <div>
                { (result == 0) && (<><p>Your result is zero</p></>)  }
                { (result > 0) && (<><p>Your result is positive</p></>)  }
                { (result < 0) && (<><p>Your result is positive</p></>)  }
            </div>
        </>
    )
}

export default Numbar;


