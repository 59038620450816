import React from 'react';

import productNotFoundImg from '../../../assets/images/productnotfoundtransp.png';

const ProductNotFound = ( props ) => {

  //  page not found
  return (
            <img style={{ width:'100%',height:'auto'}} src={productNotFoundImg} alt="not found" />
  );

}


export default ProductNotFound;
