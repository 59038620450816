import React from 'react';
import classes from './LocationItem.module.css';
import { TiLocation , TiLocationOutline } from 'react-icons/ti';

import { IoTrashBinOutline } from 'react-icons/io5';
import {BsCheckCircle} from 'react-icons/bs';

function LocationItem(props) {


    const render = (
        
              <div className={['container',classes.wrapper].join(' ')} onClick={()=> { props.locationSelected(props.locationIndex)}}>
                    <div className="row">
                          <div className="col-10">
                              <div className={[classes.name].join(' ')}>
                                    {props.name}
                              </div>
                          </div>
                          <div className="col-2">
                              {(props.isEdit) && (<div className={['text-right',classes.updateLocationIndexWrapper].join(' ')} 
                              onClick={ () => props.onUpdateDefaultIndex(props.locationIndex) }>
                              {props.isDefaultAddress ? <TiLocation size={20}/> : <TiLocationOutline size={20} />}
                              </div>)}

                              {(!props.isEdit) && (props.isChecked) && (<div className={['text-right',classes.updateLocationIndexWrapper].join(' ')}>
                              <BsCheckCircle size={20} />
                              </div>) }
                              
                          </div>
                    </div>
                    <div className="row">
                          <div className="col-10">
                              <div>
                              {props.telephone}
                              </div>
                          </div>
                    </div>
                    <div className="row">
                          <div className="col-10">
                              <div>
                              {props.address}
                              </div>
                          </div>
                    </div>
                    <div className="row">
                          <div className="col-10">
                              <div>
                              {props.zipCode}
                              </div>
                          </div>
                          <div className="col-2">
                              <div className={['text-right',classes.deleteItemWrapper,(props.isEdit) ? '':'d-none'].join(' ')} onClick={ () => props.onDeleteLocation(props.locationIndex)}>
                                    <IoTrashBinOutline size={18}/>
                              </div>
                          </div>
                    </div>
              </div>
        
    );

    return (
      <>
      { render }
      </>
    )
}


export default LocationItem

