import React from 'react';

import empty_basket from '../../../assets/images/empty_basket.svg';

const NoBasket = ( props ) => {

  //  page not found
  return (
            <img style={{ width:'100%',height:'auto'}} src={empty_basket} alt="not found" />
  );

}


export default NoBasket;
