import React from "react"
import { Route, useHistory } from "react-router-dom"
import { useAuth } from '../../utility/firebase/AuthContext';

export default function PrivateRoute({component:Component,...rest}) {
 
  const { currentUser } = useAuth();

  const history = useHistory();

  // const Component = targetObject.component;
  const others = {...rest};
  

  function redirectHandler(){

    console.log('others', others);
    history.push('/login', { nextTarget: others.location.pathname });
    // console.log('hello wolrd');
  }
  
  
  
  return (
    <Route
      {...rest}
      render={props => {
        return currentUser ? <Component {...props} /> : redirectHandler()
      }}
    ></Route>
  )
}
