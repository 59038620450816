import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';

const key_prefix = "X5wOs7kcCXhNhHmWzcaTOf9msRphyBPY"
const reactApp = (

      <BrowserRouter key={`${key_prefix}_browser_router`}>
          <React.StrictMode key={`${key_prefix}_browser_react_strict_mode`}>
            <App key={`${key_prefix}_app`} />
          </React.StrictMode>
      </BrowserRouter>
);


ReactDOM.render(
  reactApp,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
