import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import logo from '../../../assets/images/racewing-white.png';
import classes from './Register.module.css';
import NavigationItem from '../../../components/Navigation/NavigationItem/NavigationItem';
import { validator } from '../../../utility/common';
import uuid from 'react-uuid';
import Spinner from '../../../components/Spinner/Spinner';
import { useAuth } from '../../../utility/firebase/AuthContext';
import { useHistory } from "react-router-dom";
import AlertBar from '../../../components/UI/AlertBar/AlertBar';

import { db } from '../../../utility/firebase/firebaseConnection';

import Recaptcha from 'react-recaptcha';

const Register = (props) => {


  const [userForm, setUserForm] = useState(
    {
      fullname: {
        key: 'FeWmQwWF9oJV29dRBwGNhLAz7y3jbR6X',
        label: 'ชื่อ-นามสกุล',
        value: '',
        valid: false,
        touched: false,
        config: {
          type: 'text',
          placeholder: ''
        },
        rules: {
          required: true,
          pattern: /^[ก-๏]+\s[ก-๏]+$/,
          message: 'ระบุชื่อและนามสกุลเป็นภาษาไทย เช่น เอกชัย วิหาญหิมะ'
        }
      },
      email: {
        key: 'YeMhy3rET3iMTU7hn4aP25h37SQzUW4F',
        label: 'อีเมล',
        value: '',
        valid: false,
        touched: false,
        config: {
          type: 'text',
          placeholder: ''
        },
        rules: {
          required: true,
          pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
          message: 'กรุณากรอกอีเมลให้ถูกต้อง'
        }
      },
      password: {
        key: 'dECid9PCQyDtfRSoXh67S3TZeEkNPsfw',
        label: 'รหัสผ่าน',
        value: '',
        valid: false,
        touched: false,
        config: {
          type: 'password',
          placeholder: ''
        },
        rules: {
          required: true,
          minLength: 6,
          message: 'รหัสผ่านจะต้องมีอย่างน้อย 6 ตัวอักษร'
        }
      },
      confirmedPassword: {
        key: '1Pvxha9QFKS4TEm2OfLyr7nQvd2xronZ',
        label: 'ยืนยันรหัสผ่าน',
        value: '',
        valid: false,
        touched: false,
        config: {
          type: 'password',
          placeholder: ''
        },
        rules: {
          required: true,
          minLength: 6,
          message: 'รหัสผ่านจะต้องมีอย่างน้อย 6 ตัวอักษร'
        }
      }
    }
  );

  const [loading, setLoading] = useState(false);
  const [passwordNotMatch, setPasswordNotWatch] = useState(false);
  const { signup } = useAuth();
  const [error, setError] = useState("")
  const history = useHistory();

  const [robotChecked, setRobotChecked] = useState(false);

  useEffect(() => {

    document.body.style.backgroundColor = 'black';
    return (() => {
      document.body.style.backgroundColor = '#f0f0f0';
    });

  }, []);


  const verifyCallbackHandler = (response) => {
    // console.log('verify', response);
    if (response) {
      setRobotChecked(true);
    }else{
      setRobotChecked(false);
    }
  }



  const inputChangeHandler = (index, e) => {

    let updatedUserForm = { ...userForm };
    updatedUserForm[index].value = e.target.value;
    setUserForm({ ...updatedUserForm });

  }

  const registerHandler = (e) => {

    
    e.preventDefault();

    if (!robotChecked) {
      setError('กรุณายืนยันตัวตน')
    } else {


      setLoading(true);

      const updatedUserForm = { ...userForm };
      let formCheckResult = true;
      for (let key in updatedUserForm) {
        updatedUserForm[key].valid = validator(updatedUserForm[key].value, updatedUserForm[key].rules);
        updatedUserForm[key].touched = true;
        formCheckResult = formCheckResult && updatedUserForm[key].valid;

      }

      const passwordMatch = (updatedUserForm.password.value === updatedUserForm.confirmedPassword.value);
      formCheckResult = formCheckResult && passwordMatch;
      if (passwordNotMatch) { setError('รหัสผ่านไม่ตรงกัน') };

      if (formCheckResult) {

        signup(userForm.email.value, userForm.password.value).then(response => {
          
          // console.log('call back 1', response);
          const user = response.user;
          return db.collection('Users').doc(user.uid).set({
                      email: user.email,
                      level: 400,
                      levelName: "ไม่กำหนด",
                      fullname: userForm.fullname.value
                    });

        }).then(doc => {
            history.push('/');
        }).catch(error => {
          setError(`${error.message}`);
        });


      }

      setUserForm({ ...updatedUserForm });
      setPasswordNotWatch(!passwordMatch);
      setLoading(false);

    }



  };

  let form = [];

  if (!loading) {
    Object.entries(userForm).forEach(([index, row]) => {

      form.push(

        <Form.Group key={`group-${row.key}`} controlId="formBasicEmail">
          <Form.Label key={`label-${row.key}`}>{row.label}:</Form.Label>
          <Form.Control key={`input-${row.key}`} type={row.config.type} onChange={(e) => inputChangeHandler(index, e)} />
          {row.touched && !row.valid && <div>{row.rules.message}</div>}
        </Form.Group>

      );

    });
  } else {
    form = <div style={{ height: '200px' }} className="d-flex align-items-center justify-content-center"><Spinner /></div>;
  }


  //  console.log('fullname', userForm.fullname);
  //  rules.pattern.test(value)
  //  console.log('tested',userForm.fullname.rules.pattern.test(userForm.fullname.value));


  return (<>
    <div className="d-flex align-items-center" style={{ height: '100vh' }}>

      <Container className={[classes.container].join(' ')} fluid>
        <Row>

          <Col className="d-none d-lg-block col-lg-6">
            <div className={[classes.logoWrapper, 'd-flex', 'align-items-center', 'justify-content-center'].join(' ')}>
              <img src={logo} alt="racwing-white-logo" />
            </div>
          </Col>

          <Col className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-0">
            <Form onSubmit={registerHandler} className={[classes.formWrapper, 'bg-white mx-auto'].join(' ')} style={{ borderRadius: '8px', padding: '40px 30px' }} >
              <h3 className="text-center">สมัครบริการ</h3>
              <br />
              {(error) && (<AlertBar>{error}</AlertBar>)}
              {form}

              <Recaptcha
                sitekey="6LdO_8EaAAAAABeNJ-sdxFiNhyVDG6ewvEsG4hVj"
                render="explicit"
                verifyCallback={(response) => { verifyCallbackHandler(response) }}

              />
              <br />
              <Button className="mb-2" type="submit" style={{ backgroundColor: '#d34444', color: 'white', fontWeight: 400, width: '100%' }}>
                สมัครบริการ
                                </Button>

              <Form.Label htmlFor="inlineFormInputName2">
                มีบัญชีแล้ว ? <NavigationItem link="/login">ลงชื่อเข้าใช้</NavigationItem>
              </Form.Label>
            </Form>

          </Col>
        </Row>
      </Container>

    </div>
  </>
  );

}


export default Register;
