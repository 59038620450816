import React from 'react';
import Barcode from '../../../assets/images/qr_code.png';
import classes from './Barcode.module.css';

const barcode = (props) => {


return (
        <>
          <div className={[classes.barcodeWrapper].join(' ')}>
            <img src={Barcode} alt="Line official account" />
          </div>
        </>
);

}


export default barcode;
