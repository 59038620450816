import React, { useState, useEffect } from 'react';
import ImageTile from '../ImageTile/ImageTile';
import uuid from 'react-uuid'
import { Col } from 'react-bootstrap';
import Spinner from '../../Spinner/Spinner';
import { db } from '../../../utility/firebase/firebaseConnection';
import classes from './CreatedAt.module.css';
// import moment from 'moment';
import AlertModal from "../../UI/Modal/AlertModal";

//  newly-created products
const CreatedAt = (props) => {

  const [ loading, setLoading ] = useState(false);
  const [ products, setProducts ] = useState([]);
  const [ error, setError ] = useState();

  const userLevel = props.userLevel;

  useEffect(()=>{

    setLoading(true);

    const unsubscribe = db.collection('Products')
                        .orderBy('created_at','desc')
                        .limit(100)
                        .onSnapshot( (productData) => {

                          const products  = productData.docs.map(
                                            (product) => [product.id,product.data()]
                                          ).filter( ([productId,product]) => product.show === true && product.deleted === false)
                                          .slice(0,10);

                          
                          //  if show products are less than 10, show nothing
                          setProducts( products.length === 10 ? products : [] );

                          setLoading(false);

                        }, (error) => {
                            setError(error.message);
                        });


    return unsubscribe;
  },[]);


  var items = (<Col>
                  <div key={uuid()} style={{height:'300px'}} className={ ['w-100','d-flex','align-items-center','justify-content-center'].join(' ') }>
                        <Spinner key={uuid()}/>
                  </div>
              </Col>);

  if(!loading){



    items = (
              <div className={[classes.wrapper]}>

              <h2 className={[classes.header].join(' ')}>สินค้าใหม่ล่าสุด</h2>

                  <div className={[classes.innerwrapper]}>
                  {
                    products.map( ([id,product]) => {

                                   return (

                                         <div key={uuid()} className={[classes.tileWrapper].join(' ')}>
                                              <ImageTile 
                                              key={uuid()}  
                                              productId={id} 
                                              memberPrices={product.memberPrices}
                                              userLevel = {userLevel}
                                               price={product.price} 
                                               src={product.image.src } 
                                               alt={product.name} 
                                               name={product.name} />
                                         </div>

                                   );
                               })
                  }
                </div>
              </div>
            );

  }

  return (
        <React.Fragment>

                    <AlertModal message={error} alertModalClosed={()=> setError()} />

                    { items }


        </React.Fragment>
  );
}
export default CreatedAt;
