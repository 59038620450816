import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import _ from 'lodash';
const AlertModal = (props) => {

 
  return (
    <>
      <Modal show={(!_.isEmpty(props.message))}>
        <Modal.Header>
          <Modal.Title>เกิดข้อผิดพลาด</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.message}</Modal.Body>
        <Modal.Footer>
          <Button onClick={props.alertModalClosed} variant="danger">
            Close
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );

}


export default AlertModal;
