import React, { useEffect, useState } from 'react';

import logo from '../../../assets/images/racewing-red.png';
import classes from './Topbar.module.css';


import { BsList  } from 'react-icons/bs';

import numeral from 'numeral';

import home from '../../../assets/images/home.svg';
import search from '../../../assets/images/search.svg';
import cart from '../../../assets/images/cart.svg';

import Image from  '../../UI/Image/Image';


// import { subscribeBaskets } from '../../../utility/products/BasketUtility';
import { useBasket } from '../../../utility/products/BasketContext';
import { useAuth } from '../../../utility/firebase/AuthContext';


import { useHistory } from "react-router-dom"

import uuid from 'react-uuid';

import UserSpendingModal from '../../UI/Modal/UserSpendingModal';
// firebase database connection
import { db } from '../../../utility/firebase/firebaseConnection';


const Topbar = (props) => {


const history = useHistory();


const { currentUser, userSpending ,setRedirectPath } = useAuth();

const { baskets,setUserBasket,basketAnimated } = useBasket(currentUser);

const [showUserSpending, setShowUserSpending] = useState(false);

const [userSpendingLabel, setUserSpendingLabel] = useState(0);

const [userLevel, setUserLevel] = useState({ level: null });


useEffect(()=>{
    setUserBasket(currentUser);
},[currentUser]);


useEffect(() => {

    if (currentUser) {
      //  load level to determine price, 
      db.collection('Users').doc(currentUser.uid).onSnapshot(userLevelDoc => {

        if (userLevelDoc.exists) {
          setUserLevel({
            level: userLevelDoc.data().level,
            ...userLevelDoc.data()
          })
        }
      });
    }else{
        setUserLevel({ level: null });
    }

  }, [currentUser])

useEffect(()=>{
    
    const result = (userSpending > 100000) ? ' > 100k': numeral(userSpending).format('0,0.00');
    setUserSpendingLabel( result)
},[userSpending]);





function menuItemClickedHandler(link,requiredAuth = false){

    if(requiredAuth && !currentUser){
        setRedirectPath(link);
        link = '/login';
    }

    history.push(link);
}

//  content


// let totalBasketItems = baskets.reduce( (sum,basket) => basket.data().qty + sum , 0 );
let totalBasketItems = baskets.length;


if(totalBasketItems === 0){
   totalBasketItems = '';
}
if(totalBasketItems > 99 ){
  totalBasketItems = '+99';
}

console.log('userSpending passed',userSpending);

 let render = (
   <div className={[classes.topBar,'d-flex','align-items-center'].join(' ')}>


           <button key={uuid()} onClick={props.clicked} className={[classes.menuButton].join(' ')}>
               <BsList size={26}  />
           </button>

            <button key={uuid()} onClick={ () => setShowUserSpending(true)} className="btn-info btn btn-sm ml-2">
                    {userSpendingLabel}
            </button>

           <div key={uuid()} className={[classes.logo].join(' ')} onClick={()=> menuItemClickedHandler('/')}>
               <img src={logo} alt="Red wings" />
           </div>

           <button key={uuid()} className={[classes.menuButton,'ml-auto'].join(' ')} onClick={()=> menuItemClickedHandler('/')}>
               <Image src={home} name="home" selectedClasses={[classes.topbarIcon].join(' ')} />
           </button>

            <button key={uuid()} className={[classes.menuButton,'mx-0'].join(' ')} onClick={()=> menuItemClickedHandler('/search')}>
                <Image src={search} name="search" selectedClasses={[classes.topbarIcon].join(' ')} />
            </button>
            <button key={uuid()} className={[classes.menuButton,'mx-0'].join(' ')} onClick={()=> menuItemClickedHandler('/Basket',true)}>

                <Image src={cart} name="cart" selectedClasses={[classes.topbarIcon].join(' ')} /><sup><span className={[classes.badge,'badge','badge-pill',basketAnimated ? 'badge-danger':'badge-light'].join(' ')}>{totalBasketItems}</span></sup>

            </button>





   </div>
 );


  const userHasSpentPercentage = ((userSpending / 1000) > 100) ? 100 : parseInt(((userSpending / 1000)));


  return (
      <>
        { render }
        <UserSpendingModal
         userHasSpentPercentage = { userHasSpentPercentage }
         userLevelName={ (userLevel.levelName) ? userLevel.levelName.toString().toUpperCase() : 'ไม่ระบุ'  }
         userHasSpent={numeral(userSpending).format('0,0.00')}
         showUserSpending={ showUserSpending }
         userSpendingModalClosed={()=> setShowUserSpending(false) }  
         />
      </>
  );
}


export default Topbar;
