import React, { useState, useEffect } from 'react';

//  user
import { useAuth } from "../../utility/firebase/AuthContext";

import _ from 'lodash';
// firebase database connection
import { db } from '../../utility/firebase/firebaseConnection';
//  react router dom
import { useParams } from "react-router-dom";

//  component class
import classes from './ProductShow.module.css';
//  user interface
import FullScreenSpinner from '../../components/Spinner/FullScreenSpinner';
import ProductNotFound from '../../components/UI/NotFound/ProductNotFound';
import Sliders from '../../components/UI/Sliders/Sliders/Sliders';

//  product options ()
import ImageOptions from '../../components/ProductItem/ImageOptions/ImageOptions';

import ImageTile from '../../components/ProductItem/ImageTile/ImageTile';

import numeral from 'numeral';

import uuid from 'react-uuid';


//  number 
import { isPositiveInteger } from '../../utility/common/common';
import Alert from 'react-bootstrap/Alert';
import NumBar from '../../components/UI/NumBar/NumBar';


//  basket
import { useBasket } from '../../utility/products/BasketContext';

// wish list
import { useWish } from '../../utility/products/WishContext';


import { useHistory } from "react-router-dom";
// end imports


import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';


import AlertModal from '../../components/UI/Modal/AlertModal';
// import { Modal } from 'react-bootstrap';


const ProductShow = (props) => {


  const { currentUser } = useAuth();
  

  const history = useHistory();
  //  product
  const [productLoading, setProductLoading] = useState(false);
  const { productId } = useParams();
  const [product, setProduct] = useState({});


  const [userLevel, setUserLevel] = useState({ level: null });

  const { addToBasket } = useBasket();
  // const basketItems = baskets.map( basket => ({id: basket.id,...basket.data()}) )
  // const isBasket = _.find()
  // console.log('basket', basketItems);
  const key_prefix = "fbFTJFmz7zSGGNb9cD8CULL6T1JDmNEi";

  const { wishes, addToWishList } = useWish();
  const wishItems = wishes.map(wish => ({ ...wish.data() }));
  const isWished = _.find(wishItems, { productId: productId });


  const [purchaseOrder, setPurchaseOrder] = useState({

    userId: currentUser ? currentUser.uid : null,
    email: currentUser ? currentUser.email : null,
    productId: productId,
    selectedOptionIndex: 0,
    qty: 1,
    unit: '',
    specialPrice: false,
    price: 0,
    discount: 0,
    totalPrice: 0
  });


  // qty
  const [qty, setQty] = useState({
    max: 1,
    min: 1,
    value: 1,
    valid: true
  });

  const [noQty, setNoQty] = useState(false);

  // carousel 
  const [imageIndex, setImageIndex] = useState(0);


  //  error 

  const [error, setError] = useState(null);


  // other related product
  const [otherLoading, setOtherLoading] = useState(false);
  const [others, setOthers] = useState([]);


  useEffect(() => {

    if (currentUser) {
      //  load level to determine price, 
      db.collection('Users').doc(currentUser.uid).onSnapshot(userLevelDoc => {

        if (userLevelDoc.exists) {
          setUserLevel({
            level: userLevelDoc.data().level,
            ...userLevelDoc.data()
          })
        }
      });
    }


  }, [currentUser])

  // // useEffect(() => {
    
  // //   returen
  // //     console.log('image index reset')
  // //     setImageIndex(0);
  // // }, []);
  // useEffect(() => {
    
  //   console.log('page loading');
  //   // return () => {
  //   //   console.log('image index reset')
  //   //   setImageIndex(0);
  //   // }
  // }, []);


  //  load product
  useEffect(() => {

    async function loadProduct() {

      try {

        setProductLoading(true);
        setError(null);
        setImageIndex(0);

        const productData = await db.collection('Products').doc(productId).get();

        if (productData.exists) {

          const loadedProduct = { docId: productData.id, ...productData.data() }
          const memberPrices = loadedProduct.memberPrices;

          let selectedPrice = loadedProduct.price;
          let specialPrice = false;

          for (const memberPrice of memberPrices) {
            if (memberPrice.level === userLevel.level) {
              selectedPrice = memberPrice.price;
              specialPrice = true;
            }

          }

          setQty((prev) => ({ ...prev, max: loadedProduct.options[0].qty }));

          setNoQty( loadedProduct.options[0].qty <= 0);

          setProduct(
            {
              ...loadedProduct,
              // currentPrice: selectedPrice
            }
          );

          setPurchaseOrder(prev => ({
            ...prev,
            productId: loadedProduct.docId,
            price: loadedProduct.price,
            discount: selectedPrice,
            specialPrice: specialPrice,
            unit: loadedProduct.unit,
            totalPrice: selectedPrice
          }));


        }

      }
      catch (error) {
        // console.log('catching error', error.message);
        // setProduct({});
        setError(error.message);
        // console.log('product reset');

      }
      finally {
        setProductLoading(false);
      }

    }

    loadProduct();

    return () => {
      setProductLoading(false);
    };

  }, [userLevel, productId]);


  useEffect(() => {

    if (product && qty.valid) {
      setPurchaseOrder(prev => ({
        ...prev,
        totalPrice: prev.discount * qty.value,
        qty: qty.value
      }));

    }

  }, [qty]);


  // // load other product
  //
  useEffect(() => {



    let unsubscribe = null;
    if (!_.isEmpty(product) && (others.length === 0)) {
      setError(null);
      setOtherLoading(true);

      unsubscribe = db.collection('Products').where('groupId', '==', product.groupId).limit(40).onSnapshot((others) => {
        
        // console.log('productId',productId)
        const otherProducts = others.docs.map(doc => ({docId: doc.id,...doc.data()})).filter((product) => product.docId !== productId );

        setOthers(otherProducts);
        // setOthers(
        //   others.docs.map(doc => ({
        //     docId: doc.id,
        //     ...doc.data()
        //   }))
        // );
        setOtherLoading(false);
      }, (error) => {
        setError(error.message);
        setOtherLoading(false);
      });
    }

    return unsubscribe;


  }, [product]);


  function productOptionChangedHandler(optionIndex) {
    // console.log(optionIndex)
    setPurchaseOrder(prev => ({ ...prev, selectedOptionIndex: optionIndex }));
  }

  const qtyChangedHandler = (value, isClicked) => {

    const isAllowed = isPositiveInteger(value) || isClicked;

    

    if (isAllowed) {

      

      let currentProductQty = parseInt(qty.value);
     

      if (isClicked) {
        currentProductQty = currentProductQty + value;
      } else {
        currentProductQty = parseInt(value);
      }

      
      //  setError(typeof currentProductQty);
      // setError( (currentProductQty <= qty.max) ? "true":"false");

      setQty(prev => ({
        ...prev,
        value: currentProductQty,
        valid: currentProductQty <= qty.max && currentProductQty >= qty.min
      }));


    } else {

      setQty(prev => ({
        ...prev,
        value: value,
        valid: false
      }));

    }

  }


  const addToBasketHandler = () => {

    if (currentUser) {

      addToBasket(purchaseOrder);

    } else {
      history.push('/login', { nextTarget: `products/${productId}` });
    }
  }

  const addToWishListHandler = (userId, productId, buyQty) => {

    if (currentUser) {
      addToWishList(purchaseOrder)
    } else {
      history.push('/login', { nextTarget: `products/${productId}` });
    }
  }





  // console.log('image index', imageIndex);

  let render_others = (<FullScreenSpinner />);

  if (!otherLoading) {

    if (others.length !== 0) {

      render_others = (<>
        <div className="row">
          <div className="col">
            <h4>สินค้าในกลุ่มเดียวกัน</h4>
          </div>
        </div>
        <div className="row mx-0 px-0">
          {
            others.map(other => {
              return (<>
                <div className="mt-4 mx-0 px-0 col-6 col-md-4 col-lg-3 col-xl-2" 
                key={`${key_prefix}_wrapper_${other.docId}`}>
                  <ImageTile
                    key={`${key_prefix}_other_image_${other.docId}`}
                    memberPrices={product.memberPrices}
                    userLevel = {userLevel}
                    productId={other.docId}
                    price={other.price}
                    src={other.image.src}
                    alt={other.name}
                    name={other.name} />
                </div>
              </>);
            })
          }
        </div>
      </>);

    } else {
      render_others = (<>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">
            <ProductNotFound />
          </div>
        </div>
      </>);
    }
  }

  let render = (<FullScreenSpinner />);

  if (!productLoading) {

    if (_.isEmpty(product)) {

      render = (<>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">

              <ProductNotFound />

            </div>
          </div>
        </div>

      </>)
    } else {
      // console.log('product image order',[{...product.image,show:true},...product.images.filter( image => image.show === true)] )

      render = (<>
        <div className={classes.wrapper}>

          <div className="container">

            <div className="row bg-white" style={{ paddingTop: '15px', paddingBottom: '15px' }}>

              <div className="col-12 col-md-10 offset-md-1 col-lg-5 offset-lg-1">

                <div className="mt-lg-3">
                  <Sliders fade={true} interval={null}
                    idPrefix={productId}
                    controlClicked={(targetIndex) => setImageIndex(targetIndex)}
                    selectedOptionIndex={imageIndex}
                    images={[{...product.image,show:true},...product.images.filter( image => image.show === true)]}
                  />
                </div>

                <div className={classes.imageOptionRow}>

                  <ImageOptions idPrefix={productId}
                    activeIndex={imageIndex}
                    imageOptionClicked={(index) => setImageIndex(index)}
                    images={[{...product.image,show:true},...product.images.filter( image => image.show === true)]} />

                </div>



              </div>


              <div className="col-12 col-md-10 offset-md-1 col-lg-5 offset-lg-0">

                <div className={['container', classes.productMainWrapper].join(' ')} >

                  <div className="row">
                    <div className="col-12 mt-3">
                      <h3>{product.name}</h3>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-12">
                      <div className={[classes.priceWrapper].join(' ')}>
                        {`${numeral(purchaseOrder.totalPrice).format('0,0.00')} บาท / ${purchaseOrder.qty} ${product.unit}`}
                        {(purchaseOrder.specialPrice) && (<>
                          &nbsp;&nbsp;
                        <span style={{textDecoration:'line-through'}}>
                          {numeral(purchaseOrder.price).format('0,0.00')}
                        </span>
                        &nbsp;บาท
                          </>) }
                      </div>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-12">
                      <span>ระบุตัวเลือก</span>
                    </div>
                  </div>

                  <div className="row mb-3">

                    <div className="col-12">
                      <div className={[classes.optionWrapper, 'd-flex', 'flex-wrap', 'justify-content-between'].join(' ')}>
                        {
                          product.options.map((option, index) => {
                            return (
                              <span
                                key={uuid()}
                                title={option.name}
                                onClick={() => productOptionChangedHandler(index)}
                                className={[classes.option, (index === purchaseOrder.selectedOptionIndex) ? classes.selectedOptionIndex : ''].join(' ')}>
                                { option.name}
                              </span>)
                          })
                        }
                      </div>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-12">
                      <span key={uuid()}>ระบุจำนวน (จำนวนสินค้าที่สั่งได้ {`${qty.max} ${purchaseOrder.unit}`})</span>
                    </div>
                  </div>

                  <div className="row mb-3">

                    <div className="col-12">

                      <NumBar
                        min={qty.min}
                        max={qty.max}
                        qty={qty.value}
                        qtyChanged={(value, isClicked) => qtyChangedHandler(value, isClicked)}
                      />

                      <div className="mt-3">
                        {!qty.valid && <Alert className="mb-0" variant={"danger"}>จำนวนสินค้าไม่ถูกต้อง กรุณาระบุจำนวนให้ถูกต้อง</Alert>}
                      </div>

                      <div className="mt-3">
                        {noQty && <Alert className="mb-0" variant={"danger"}>ไม่สามารถเพิ่มสินค้าลงตะกร้าได้ เนื่องจากสินค้าหมด</Alert>}
                      </div>
                    </div>

                  </div>

                  <div className="row" style={{ marginBottom: '15px' }}>

                    <div className="col-12">
                      <button key={uuid()} disabled={!qty.valid || noQty} onClick={() => addToBasketHandler()} className={[classes.btnBasket].join(' ')}>เพิ่มลงตะกร้า</button>
                      <button
                        key={uuid()}
                        disabled={!qty.valid}
                        onClick={() => addToWishListHandler()}
                        className={[classes.btnWish].join(' ')}>


                        {(isWished) ? <AiFillHeart /> : <AiOutlineHeart />}&nbsp;ถูกใจ
                      </button>
                    </div>

                  </div>



                </div>

              </div>

            </div>

            <div className={['row', 'bg-white', 'py-4', 'px-4', classes.productDetailWrapper].join(' ')}>
              <div className="col-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">

                <div className={[classes.colWrapper].join(' ')}>
                  <section key={uuid()}
                    dangerouslySetInnerHTML={{ __html: product.description }} />
                </div>
              </div>

            </div>


          </div>



        </div>



        <div className={[classes.productTileContainer].join(' ')}>

          <div className="container">

            {render_others}

          </div>

        </div>
      </>);
    }
  }



  const errorModal = (<>
    <AlertModal
      message={error}
      alertModalClosed={() => { setError(null) }}
    />
  </>);



  return (
    <React.Fragment>

      { render}

      <AlertModal message={error} alertModalClosed={() => setError(null)} />

    </React.Fragment>
  );
}
export default ProductShow;
