import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import Image from '../../Image/Image';
// import uuid from 'react-uuid';


const Sliders = (props) => {

  // console.log('interval',props.interval);
  const key_prefix = "J94HFpu1HTnRlDUyJt2JRxppnvEQN6lf_";

  return (
    <Carousel
     key={`${key_prefix}_carousel`}
     onSelect={(targetIndex, event)=> props.controlClicked(targetIndex)}
     interval={(props.interval) ? parseInt(props.interval) : null}
     fade={props.fade}
     activeIndex={parseInt(props.selectedOptionIndex)}
     >

     {
        props.images.map( (image,index) => {
          return (
            <Carousel.Item key={`${key_prefix}_carousel_${index}`} >
              <Image
                selectedClasses="d-block w-100"
                src={image.src}
                name={image.name}
                key={`${key_prefix}_${index}`}
              />
            </Carousel.Item>
          )
        })

     }

   </Carousel>
          );

}

export default Sliders;
