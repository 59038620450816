import React from 'react';

import image from '../../../assets/images/noimage1.png';

const Image = (props) => {

 
  function ImageNotFound(e){

      // console.log('not found');
      e.target.src = image;
      e.target.title = 'not found';
      e.target.alt = 'not found';
  }

  return (
    <img className={props.selectedClasses} onError={ImageNotFound} src={props.src} alt={props.name} title={props.name} />
  )

};

export default Image;
