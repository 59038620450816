import React from 'react'
import Image from '../UI/Image/Image';
import classes from './BrandItem.module.css';
import { Link } from 'react-router-dom';

function BrandItem({brandId,name,src, brandItemClicked}) {

    return (
        <>
            <Link to={`/productBrand/${brandId}/${name}`} >
                <div className={classes.BrandImage} >   
                        <Image  src ={src} />
                </div>
            </Link>  
        </>
    )
}

export default BrandItem
