import React from 'react';

import no_purchase_history from '../../../assets/images/no_purchase_history.svg';

const NoPurchase = ( props ) => {

  //  page not found
  return (
            <img style={{ width:'100%',height:'auto'}} src={no_purchase_history} alt="not found" />
  );

}


export default NoPurchase;
