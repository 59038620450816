import React, { useState } from 'react'

import { db } from '../../../utility/firebase/firebaseConnection';
import firebase from 'firebase/app'

import { useAuth } from '../../../utility/firebase/AuthContext';

import classes from './CreateLocation.module.css';

import json_provinces from './provinces.json';
import Select from 'react-select'

import _ from 'lodash';

import { useHistory } from "react-router-dom";

function CreateLocation(props) {

const { currentUser } = useAuth();
const history = useHistory();

const [ formChecked, setFormChecked] = useState(false);


const  [userLocation , setUserLocation ] =  useState({
    name :{
        id: `location_form_input_name`,
        value : '',
        rules : {
            required: true,
            minLength:4,
            pattern1: /^[a-zA-Z\s]+$/,
            pattern2: /^[ก-๏\s]+$/
        },
        feedback:'กรุณากรอกชื่อและนามสกุล (ขั้นต่ำ 4 ตัวอักษร)',
        valid:false
    },
    telephone:{
        id: `location_form_input_telephone`,
        label:'เบอร์โทรศัพท์',
        value : '',
        rules : {
            required:true,
            pattern1: /^\(?([0-9]{3})\)?[- ]?([0-9]{3})[- ]?([0-9]{4})$/,
            pattern2: /^\(?([0-2]{2})\)?[- ]?([0-9]{3})[- ]?([0-9]{4})$/,
            pattern3: /^\(?([0-9]{3})\)?[- ]?([0-9]{3})[- ]?([0-9]{3})$/,
        },
        feedback:'กรุณากรอกเบอร์โทรศัพท์ของท่าน เช่น 02-523-4567,025234567,0873214567,087-321-4567',
        valid:false
    },
    address:{
        id: `location_form_input_address`,
        label:'ที่อยู่',
        value : '',
        rules : {
            required:true,
            minLength:10
        },
        feedback: 'กรุณาระบุที่อยู่ อย่างน้อย 10 ตัวอักษร',
        valid:false
    },
    province:{
        id: `location_form_input_province`,
        label:'จังหวัด',
        value : {},
        options: json_provinces.map( province => (
                    {
                        value : {provinceID: province.ID , provinceName: province.Name , districts: province.districts} ,
                        label : province.Name
                    }
            )),
        rules : {
            requiredObject:true,
        },
        feedback:'กรุณากรอกชื่อจังหวัด',
        valid:false
    },
    district:{
        id: `location_form_input_district`, 
        label:'เขต/อำเภอ',
        value : {},
        options:[],
        rules : {
            requiredObject:true,
        },
        feedback:'กรุณาระบุ เขต / อำเภอในประเทศไทย',
        valid:false
    },
    tambon:{
        id: `location_form_input_tambon`,
        label:'แขวง/ตำบล',
        value : {},
        options:[],
        rules : {
            requiredObject:true,
        },
        feedback:'กรุณาระบุ แขวง / ตำบลในประเทศไทย',
        valid:false
    },
    zipCode:{
        id: `location_form_input_zip`,
        label:'รหัสไปรษณีย์',
        value : '',
        rules : {
            required : true,
            length: 5,
            pattern1: /(^\d{5}$)/
        },
        recommended : '',
        showRecommended: false,
        feedback:'กรุณากรอกรหัสไปรษณีย์ เช่น 10230,12500',
        valid:false
    }
});



function locationFormChangedHandler(index,value){

    const updatedUserLocationForm = {...userLocation};
    const targetedInput = updatedUserLocationForm[index];

    targetedInput.value = value;
    // targetedInput.touched = true;
    // targetedInput.valid = validateInput(targetedInput.value,targetedInput.rules);

    setUserLocation({
        ...updatedUserLocationForm,
        targetedInput
    });


    // let formReady = true;
    // for(let frmParam in userLocation){
    //     formReady = formReady && userLocation.valid;
    // }

    // setFormReady(formReady);




}

function validateInput(value,rules){
    let checkResult = true;

    if(!rules){
        return true;
    }
    if(rules.required){
        checkResult = checkResult &&  value.toString().trim() !=='';
    }
    if(rules.requiredObject){
        checkResult = checkResult &&  !_.isEmpty(value);
    }
    if(rules.minLength){
        checkResult = checkResult &&  value.length >= rules.minLength;
    }
    if(rules.maxLength){
        checkResult = checkResult &&  value.length <= rules.maxLength;
    }
    if(rules.pattern1){
        let patternResult = rules.pattern1.test(value) || ((rules.pattern2) ? rules.pattern2.test(value) : false)|| ((rules.pattern3) ? rules.pattern3.test(value) : false);

        checkResult = checkResult && patternResult;
    }

    if(rules.number){
        const pattern = /^\d+$/;
        checkResult = checkResult && pattern.test(value);
    }

    return checkResult;
}


function validateLocationFormData(){

        setFormChecked(true);

        //  check if form is ready
        let formReady = true;

        for (const [key] of Object.entries(userLocation)) {

            const value = userLocation[key].value;
            const rules = userLocation[key].rules;
            const checkResult = validateInput(value,rules);
             // update result
            const updatedUserLocationForm = {...userLocation};
            const targetUserLocation = updatedUserLocationForm[key];
            targetUserLocation.valid = checkResult;

            formReady = formReady && checkResult;

            setUserLocation({
                ...updatedUserLocationForm,
                targetUserLocation
            });
        }

        if(formReady){
            locationFormSubmittedHandler();
        }

}

function locationFormSubmittedHandler(){
    //  uploading data to firestore
    // if success , redirect to location  to show all addresses
    // if failed , show pop up
    // db.

    const location = {
        name: userLocation.name.value,
        telephone: userLocation.telephone.value,
        address:userLocation.address.value,
        province: userLocation.province.value.provinceName,
        district: userLocation.district.value.districtName,
        tambon : userLocation.tambon.value.tambonName,
        zipCode: userLocation.zipCode.value,
        visible: true
    };


    if(currentUser){
        db.collection("Locations")
        .doc(currentUser.uid)
        .set({
            locations : firebase.firestore.FieldValue.arrayUnion(location)
        },{merge:true});
    }

    // history.push('location');
    // history.push
    // console.log('next target',props.location.state );
    const nextTarget = _.has(props,"location.state.nextTarget") ? props.location.state.nextTarget : 'location';
    // const payload = _.has(props,"location.state.payload") ? props.location.state.payload : null;
    
    // console.log('create location',payload);
    history.push({
        pathname: nextTarget
      });

}

function provinceChangedHandler(option,action){

    const updatedUserLocationForm = {...userLocation};
    const district = updatedUserLocationForm['district'];
    const tambon   = updatedUserLocationForm['tambon'];
    const zipCode  = updatedUserLocationForm['zipCode'];

    district.options = option.value.districts.map(district => (
                        {
                            label : district.name ,
                            value : {
                                      districtID : district.ID ,
                                      districtName: district.name ,
                                      tambons: district.tambons
                                    }
                        }));


    // reset district
    district.value = {};
    tambon.value = {};
    zipCode.value = '';
    zipCode.showRecommended = false;
    setUserLocation({
        ...updatedUserLocationForm,
        district,
        tambon,
        zipCode
    });

    locationFormChangedHandler('province',{'provinceID': option.value.provinceID , 'provinceName': option.value.provinceName });



}

function districtChangedHandler(option,action){

    const updatedUserLocationForm = {...userLocation};
    const tambon = updatedUserLocationForm['tambon'];
    const zipCode  = updatedUserLocationForm['zipCode'];

    tambon.options = option.value.tambons.map(tambon => (
        {
            label: tambon.Name ,
            value : {
                    'tambonID' : tambon.ID,
                    'tambonName': tambon.Name,
                    'zipCode': tambon.PostCodeMain,
                    'zipCodeAll': tambon.PostCodeAll
        }}
    ));

    tambon.value = {};
    zipCode.value = '';
    zipCode.showRecommended = false;


    setUserLocation({
        ...updatedUserLocationForm,
        tambon,
        zipCode
    });


    locationFormChangedHandler('district',{'districtID':option.value.districtID,'districtName':option.value.districtName});

}

function tambonChangedHandler(option,action){

    const updatedUserLocationForm = {...userLocation};
    const targetedInput = updatedUserLocationForm['zipCode'];

    targetedInput.value = option.value.zipCode;
    targetedInput.showRecommended = true;
    targetedInput.recommended = 'รหัสไปรษณีย์ ที่แนะนำ ' + option.value.zipCodeAll;

    setUserLocation({
        ...updatedUserLocationForm,
        targetedInput
    });

    locationFormChangedHandler('tambon',{'tambonID':option.value.tambonID,'tambonName':option.value.tambonName});

}





    return (
        <>
        <div className={classes.wrapper}>


                <div className="container">

                    <div className="row">
                            <div className="col-12">
                                    <h2 className={classes.header}>ที่อยู่สำหรับจัดส่งสินค้า/ออกใบเสร็จ</h2>
                            </div>
                    </div>

                    <div className="row">

                            <div className="col-12 col-md-12 offset-md-0 col-lg-10 offset-lg-1">

                                <div className={[classes.formWrapper].join(' ')}>

                                    <div className="container">

                                            
                                            <div className="form-row">


                                                    <div className="form-group col-md-6">

                                                            <label htmlFor="inputEmail4">ชื่อ-นามสกุล</label>
                                                            <input
                                                            key={userLocation.name.id}
                                                            onChange={ e =>  locationFormChangedHandler('name',e.target.value) }
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="ชื่อ-นามสกุล / ชื่อบริษัท / ชื่อผู้รับสินค้า" />
                                                            { (formChecked) && !userLocation.name.valid && (<div className="invalid-feedback d-block">{userLocation.name.feedback}</div>) }

                                                    </div>

                                                    <div className="form-group col-md-6">
                                                            <label htmlFor="inputEmail4">เบอร์โทรศัพท์</label>
                                                            <input
                                                            key={userLocation.telephone.id}
                                                            onChange={ e =>  locationFormChangedHandler('telephone',e.target.value) }
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="หมายเลขสำหรับติดต่อ" />
                                                            { (formChecked) && !userLocation.telephone.valid && (<div className="invalid-feedback d-block">{userLocation.telephone.feedback}</div>) }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                            <label htmlFor="inputEmail4">จังหวัด</label>
                                                            <Select
                                                            value={ userLocation.province.options.filter( option => userLocation.province.value.provinceID === option.value.provinceID ) }
                                                            onChange={ (option,action) =>  provinceChangedHandler(option,action) }
                                                            placeholder={'จังหวัดในประเทศไทย'}
                                                            options={ userLocation.province.options } />

                                                            { (formChecked) && !userLocation.province.valid && (<div className="invalid-feedback d-block">{userLocation.province.feedback}</div>) }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                            <label htmlFor="inputEmail4">เขต / อำเภอ</label>
                                                            <Select
                                                            value={ userLocation.district.options.filter( option => userLocation.district.value.districtID === option.value.districtID ) }
                                                            onChange={ (option,action) =>  districtChangedHandler(option,action) }
                                                            placeholder={ 'เลือกเขต/อำเภอ' }
                                                            options={ userLocation.district.options } />

                                                            { (formChecked) && !userLocation.district.valid && (<div className="invalid-feedback d-block">{userLocation.district.feedback}</div>) }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                            <label htmlFor="inputEmail4">แขวง / ตำบล</label>
                                                            <Select
                                                            value = {userLocation.tambon.options.filter( option => option.value.tambonID === userLocation.tambon.value.tambonID)}
                                                            onChange={ (option,action) =>  tambonChangedHandler(option,action) }
                                                            placeholder={ 'เลือกแขวง/ตำบล'}
                                                            options={ userLocation.tambon.options } />

                                                            { (formChecked) && !userLocation.tambon.valid && (<div className="invalid-feedback d-block">{userLocation.tambon.feedback}</div>) }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                            <label htmlFor="inputEmail4">รหัสไปรษณีย์</label>
                                                            <input
                                                            onFocus= {e => e.target.select() }
                                                            key={userLocation.zipCode.id}
                                                            value={userLocation.zipCode.value}
                                                            onChange={ e =>  locationFormChangedHandler('zipCode',e.target.value) }
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="รหัสไปรษณีย์" />
                                                            { (formChecked) && !userLocation.zipCode.valid && (<div className="invalid-feedback d-block">{userLocation.zipCode.feedback}</div>) }
                                                            { userLocation.zipCode.showRecommended && (<div className="invalid-feedback text-success d-block">{userLocation.zipCode.recommended}</div>) }
                                                    </div>

                                                    <div className="form-group col-md-12">
                                                            <label htmlFor="inputEmail4">ที่อยู่</label>
                                                            <textarea
                                                            key={userLocation.address.id}
                                                            onChange={ e =>  locationFormChangedHandler('address',e.target.value) }
                                                            className="form-control"
                                                            rows="3"
                                                            placeholder="กรุณาระบุที่อยู่อย่างละเอียด เพื่อความสะดวกในการจัดส่งสินค้า/ออกใบเสร็จ">
                                                            </textarea>
                                                            { (formChecked) && !userLocation.address.valid && (<div className="invalid-feedback d-block">{userLocation.address.feedback}</div>) }
                                                    </div>

                                                    <div className="form-group col-md-12">
                                                            <div className={classes.btnAddress}>
                                                            <button onClick={ () => validateLocationFormData()} className="btn btn-danger btn-block">บันทึก</button>
                                                            </div>
                                                    </div>
                                                

                                            </div>


                                        
                                    </div>

                                </div>

                            </div>

                    </div>


                </div>



        </div>

        </>
    )
}

export default CreateLocation;
