import React, { useState, useEffect } from 'react';
import ImageTile from '../ImageTile/ImageTile';

import Spinner from '../../Spinner/Spinner';

import { db } from '../../../utility/firebase/firebaseConnection';
import classes from './Store.module.css';
// import AlertModal from "../../UI/Modal/AlertModal";
import ProductNotFound from '../../UI/NotFound/ProductNotFound';

import InfiniteScroll from "react-infinite-scroll-component";
// import _ from 'lodash';


//  product in store
const Store = (props) => {

  // const lastestDoc = useRef(null);
  const [lastestDoc, setLastestDoc] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const key_prefix = "Pm7]5CEOGw!S6Wi_";

  const userLevel = props.userLevel;




  useEffect(() => {


    // fetchProducts();
    setLoading(true);
    let unsubscribe;
    unsubscribe = db.collection('Products')
      .where('show', '==', true)
      .limit(20).onSnapshot(productData => {
        // console.log('use effect loading', productData)

        if (productData.size !== 0) {

          const products = productData.docs.map(productDoc => ({ docId: productDoc.id, ...productDoc.data() })).filter(product => product.show === true && product.deleted == false);
          // console.log('product exist',products);
          setProducts(products);

          setLastestDoc(productData.docs[productData.size - 1]);

          // console.log('latest doc',productData.docs[productData.size -1].data());

        }
        // else{
        //   console.log('product not exist')
        // }
        setLoading(false);
      }, (error) => {
        setLoading(false);
      }, () => {
        setLoading(false);
      });

    return () => {
      unsubscribe && unsubscribe();
    }

  }, []);

  async function fetchProducts() {

    const productRef = db.collection('Products')
      .where('show', '==', true)
      .startAfter(lastestDoc || 0)
      .limit(10);

    const productData = await productRef.get();
    if (!productData.empty) {
      const nextProducts = productData.docs.map(productDoc => ({ docId: productDoc.id, ...productDoc.data() })).filter(product => product.show === true && product.deleted == false);
      setProducts(prev => prev.concat(nextProducts));

      setLastestDoc(productData.docs[productData.size - 1]);
      
    } else {
      setHasMore(false);
    }
    // setHasMore(false);

  }


  let render = (<>
    <div className="text-center">
      <Spinner key={`${key_prefix}_spinner`} />
    </div>
  </>);

  if (!loading) {

    if (products.length === 0) {


      render = (<>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
              <ProductNotFound key={`${key_prefix}product_not_found`} />
            </div>
          </div>
        </div>

      </>);

    } else {
      render = (
        <>
          <h2 className={classes.myHeader}>สินค้าในคลัง</h2>
          <div className={[classes.myContainer].join(' ')}>
            <div className="container">
              {/* start */}
              <InfiniteScroll
                key={`${key_prefix}_infinite_scroll`}
                dataLength={products.length}
                next={fetchProducts}
                hasMore={hasMore}
                loader={<div className={classes.spinnerWrapper}><Spinner key={`${key_prefix}_infinite_spinner`} /></div>}
              >

                <div className="row mx-0">
                  {
                    products.map((product,index) => {
                      return (
                        
                        <div key={`${key_prefix}_wrapper_${index}_${product.docId}`} 
                        className="col-6 col-md-4 col-lg-3 col-xl-2 mt-4 mx-0 px-0">
                          <ImageTile key={`${key_prefix}_${product.docId}`}
                            productId={product.docId}
                            memberPrices={product.memberPrices}
                            userLevel = {userLevel}
                            price={product.price}
                            src={product.image.src}
                            alt={product.name}
                            name={product.name} />
                        </div>
                      );
                    })
                  }

                </div>

              </InfiniteScroll>

              {/* end */}

            </div>

          </div>
        </>
      );
    }

  }

  return (
    <React.Fragment>
      { render}

    </React.Fragment>
  );
}
export default Store;
