import React,{ useEffect, useState } from 'react';
import BasketTile from './BasketTile/BasketTile';
import { db } from  '../../utility/firebase/firebaseConnection';
import FullScreenSpinner from '../Spinner/FullScreenSpinner';
import classes from './BasketTiles.module.css';
import numeral from 'numeral';
import _ from 'lodash';


import { useAuth } from '../../utility/firebase/AuthContext';
import { useBasket } from '../../utility/products/BasketContext';
import { useWish } from '../../utility/products/WishContext';

const BasketTiles = (props) => {

  const key_prefix = "Ret7QWFATYmEKZo8ukL8KMfhC3O7o4QM";

  const [ orders, setOrders ] = useState([]);
  const [ orderLoading, setOrderLoading ] = useState(false);

  const { currentUser } = useAuth();
  const [userLevel, setUserLevel] = useState({ level: null });

  const { baskets, setUserBasket,deleteBasket } = useBasket();
  const { wishes,addToWishList } = useWish();
  const wishItems = wishes.map( wish => wish.data().productId );

  // const loadedOrders = props.loadedOrders;

  // props.basketErrors.map( error => {
  //   console.log('basket error', error);
  // })

  useEffect(()=>{
      setUserBasket(currentUser);
  },[currentUser]);


  useEffect(() => {

    if (currentUser) {
      //  load level to determine price, 
      db.collection('Users').doc(currentUser.uid).onSnapshot(userLevelDoc => {

        if (userLevelDoc.exists) {
          setUserLevel({
            level: userLevelDoc.data().level,
            ...userLevelDoc.data()
          })
        }
      });
    }
    else{
      setUserLevel({ level: null });
    }
  }, [currentUser])


  useEffect(()=>{


    async function loadOrders(){

        const orders = [];
        try
        {
             setOrderLoading(true);
             for(let i = 0 ; i < baskets.length; i ++){

                

                const [ basketId , basket ] = [ baskets[i].id, baskets[i].data() ];
                const response = await db.collection("Products").doc(basket.productId).get();
                const productId = response.id;
                const product = response.data();

                // console.log('each order',basket);
                const memberPrices = product.memberPrices;
                const selectedPrice = memberPrices.filter(memberPrice => memberPrice.level === userLevel.level);
                const price = product.price;
                const discount=  (selectedPrice.length > 0) ? selectedPrice[0].price : price;

         
                
                orders.push({
                      index: i,
                      basketId: basketId,
                      productId: productId,
                      productName: product.name,
                      selectedOptionIndex: basket.optionIndex,
                      selectedOptionName: product.options[basket.optionIndex].name,
                      // image: product.images[basket.optionIndex].src,
                      image: product.image.src,
                      qty: basket.qty,
                      maxQty: product.options[basket.optionIndex].qty,
                      unit: product.unit,
                      totalPrice: discount * basket.qty,
                      price: basket.price,
                      discount: discount,
                      checked: false,
                      valid: basket.qty <= product.options[basket.optionIndex].qty
                });
                
               
             }

            

        }catch{

        }finally{
          setOrders(orders);
          setOrderLoading(false);

        }




    }

      (baskets.length >= 0) && (userLevel) && loadOrders();
    
  },[baskets,userLevel]);


  const orderCheckedHandler = (orderIndex) => {

    const updatedOrders = [...orders];

    const targetOrder = updatedOrders[orderIndex];

    

    targetOrder.checked = !targetOrder.checked;

    updatedOrders[orderIndex] = targetOrder;

    setOrders(
      [...updatedOrders]
    );

    props.basketChanged();

  }

  const qtyChangedHandler = (orderIndex, newQty) => {


    const updatedOrders = [...orders];
    const targetOrder = updatedOrders[orderIndex];
    const validOrder = (_.toNumber(newQty) <= _.toNumber(targetOrder.maxQty)) && (_.toNumber(newQty) > 0);

    console.log('qty changed')
    targetOrder.qty = newQty;
    
    targetOrder.valid = validOrder;

    if(validOrder){
      targetOrder.totalPrice = targetOrder.discount * newQty;
    }
    

    updatedOrders[orderIndex] = targetOrder;

    // console.log(_.toNumber(newQty) <= _.toNumber(targetOrder.maxQty));

    console.log('qty chnaged', targetOrder);

    setOrders(
      [...updatedOrders]
    );

    props.basketChanged();

  }

  const checkAllorderHandler = (checkAllState) => {
      // console.log(checkAllState)

    const updatedOrders = [...orders];
    const total = updatedOrders.length;


    for( let i = 0; i < total; i++ ){
        if(updatedOrders[i].valid){
          updatedOrders[i].checked =  checkAllState;
        }
    }

    setOrders(
      [...updatedOrders]
    );

  }


  const addToWishHandler = (wishedOrder) => {

        console.log('wishedOrder',wishedOrder);

        addToWishList({
          userId : currentUser.uid,
          email : currentUser.email,
          productId: wishedOrder.productId
        })
  }

  const deleteBasketHandler = (basketId) => {

        deleteBasket(basketId);
  }


  let render = (<FullScreenSpinner/>);


  let totalPrice = orders.reduce((sum,order) => {
    return sum +  _.toNumber((order.checked) ? order.totalPrice: 0);
  },0);



  let totalQty = orders.reduce((sum,order) => {
    return sum +  _.toNumber((order.checked) ? order.qty: 0);
  },0);



  if(!orderLoading){

      if(orders.length > 0 ){

            render = orders.map( (order,index) => {
              
              // console.log('wishItems',wishItems);
              return (
                  <BasketTile
                  idPrefix={`${order.basketId}_${index}`}
                  key={`${key_prefix}_bakset_tile_${order.basketId}_${index}`}
                  order = {order}
                  qtyChanged = { (orderIndex,newQty) => qtyChangedHandler(orderIndex,newQty)}
                  orderChecked={(orderIndex)=> orderCheckedHandler(orderIndex) }
                  addToWish = { (wishedOrder) => addToWishHandler(wishedOrder) }
                  deleteBasket={(basketId) => deleteBasketHandler(basketId) }
                  valid = {order.valid}
                  liked = { (wishItems.filter( wish => wish === order.productId).length > 0) }
                  />
              );
            });

      }else{

            render = <><h2 className="text-center">ไม่มีสินค้า</h2></>;

      }

  }




  return (<>
            <div className="container bg-white" style={{marginTop:'0px'}} >

              <div className="row">
                <div className="col-2">
                    <div className={['d-flex','align-items-center','justify-content-center','h-100',classes.basketCheckboxWrapper].join(' ')}>
                        <input  type="checkbox" onChange={e => checkAllorderHandler(e.target.checked)} />
                    </div>
                </div>
                <div className="col-10 px-0">
                    <span style={{padding:'0px'}}>เลือกทั้งหมด</span>
                </div>
              </div>

              {render}


              <div className="row">

                <div className="col-12">
                  <div className={['text-right',classes.totalPriceWrapper].join(' ')}>
                    รวม {numeral(totalQty).format('0,00')} ชิ้น  {numeral(totalPrice).format('0,00.00')} บาท
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="col-12">
                      {
                       props.basketErrors.map( error => {
                        return (<p className="text-danger">*{error}</p>);
                      })
                    }
                </div>
              </div>

              <div className="row">

              <div className="col-12">
                <div className="text-right">
                <button className={['btn','btn-danger',classes.btnBasketConfirmed].join(' ')} onClick={() => props.basketConfirmed(orders) }>บันทึก</button>
                </div>                
                      
              </div>

              </div>


            </div>
          </>);

}


export default BasketTiles;
