import React from 'react';
import uuid from 'react-uuid';




const map = (props) => {

return (<>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.369061225465!2d99.87804141483119!3d13.816863690305352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e31ddb9130c1e9%3A0xd004f8ce85dd9f8c!2z4Lio4Li54LiZ4Lii4LmM4LiB4Lij4Liw4LiI4Liy4Lii4Liq4Li04LiZ4LiE4LmJ4LiyIFJhY2VXaW5n!5e0!3m2!1sen!2sth!4v1612162625140!5m2!1sen!2sth"
    width="100%"
    height="400"
    frameBorder="0" style={{border:'solid 4px white'}}
    allowFullScreen=""
    aria-hidden="false"
    title={uuid()}
    tabIndex="0"></iframe>
  </>);

}


export default map;
