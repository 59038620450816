import React from 'react'
import classes from './Payment.module.css';


import scbImage from '../../assets/images/banks/scb.svg';
import kbankImage from '../../assets/images/banks/kbank.svg';
import bblImage from '../../assets/images/banks/bbl.svg';
import TMBImage from '../../assets/images/banks/tmb.svg';

import { NavLink } from "react-router-dom";


import ReactPlayer from "react-player"



function Payments() {


    const render = (<>
                            <h4 className={classes.header}>วิธีการแจ้งชำระเงิน</h4>
                            <p>1. เมื่อท่านกดสั่งซื้อสินค้าแล้ว ท่านสามารถโอนเงินเข้าบัญชีธนาคารดังต่อไปนี้
                            </p>
                            <p> <strong>ชื่อบัญชี บริษัทไทย ออโตโมทีฟ พาร์ท จำกัด (TAP)</strong></p>
                          
                            <table className={classes.bankList}>
                              
                                <tbody>
                                    <tr>
                                        <td>
                                            <img src={scbImage} alt="scb_icon" className={classes.bankIcon}/>
                                            <span>ไทยพาณิชย์</span>
                                        </td>
                                        <td>    
                                        165-255218-2
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <img src={kbankImage} alt="kbank_icon" className={classes.bankIcon}/>
                                            <span>กสิกรไทย</span>
                                        </td>
                                        <td>    
                                            029-3-24129-5
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={bblImage} alt="bbl_icon" className={classes.bankIcon}/>
                                            <span>กรุงเทพ</span>
                                        </td>
                                        <td>    
                                            086-7-20166-7
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src={TMBImage} alt="tmb_icon" className={classes.bankIcon}/>
                                            <span>ทหารไทย</span>
                                        </td>
                                        <td>    
                                            212-2-33982-9
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                           
                           
                            <p>2. กดที่เมนูรายการสั่งซือสินค้า เลือกรายการสินค้าที่ท่านต้องการชำระเงิน</p>
                            <p>3. เลือกแจ้งชำระเงิน</p>
                            <p>4. กดอัปโหลดรูปสลิป</p>
                            <p>5. กดยืนยันการชำระเงินสถานะของรายการสั่งซื้อจะเปลี่ยนจาก รอการชำระเงินเป็น ได้รับแจ้งการชำระเงิน</p>
                            <p>6. เมื่อบริษัทได้ตรวจสอบยอดการชำระเงินของท่านแล้ว บริษัทจะทำการจัดส่งสินค้าให้ท่าน และสถานะรายการสั่งซื้อของท่านจะเปลี่ยนเป็น จัดส่งแล้ว</p>
                            
                            <p>7. หากมีปัญหาใดๆในการชำระเงิน ท่านสามารถติดต่อบริษัทได้เบอร์ 099-349-6296 หรือ ตรวจสอบช่องทางการติดต่ออื่นๆได้ <NavLink className={classes.link} to="/contact">ที่นี่</NavLink> 
                            </p>
                            
                            <div className={classes.videoWrapper}>
                                <ReactPlayer url="https://youtu.be/9Pj3WXZYPzA" width="auto"  />
                            </div>
                            
                            <h4 className={classes.header}>วิธีการยกเลิกคำสั่งซื้อ</h4>
                            <p>หากท่านยังไม่ได้แจ้งชำระเงิน ท่านสามารถยกเลิกคำสั่งซื้อได้ตามขั้นตอนดังต่อไปนี้</p>
                            <p>1. กดที่คำสั่งซื้อของท่านเลือก ยกเลิกคำสั่งซื้อ</p>
                            <p>2. กดยืนยันเพื่อยกเลิกคำสั่งซื้อของท่าน</p>
                            <p>หากท่านแจ้งชำระเงินแล้ว ท่านสามารถติดต่อบริษัทได้เบอร์ 099-349-6296 หรือ ตรวจสอบช่องทางการติดต่ออื่นๆได้ <NavLink className={classes.link} to="/contact">ที่นี่</NavLink> เพื่อขอยกเลิกคำสั่งซื้อ <span className="text-danger">บริษัทขอสงวนสิทธิ์ในการกำหนดวิธีการคืนเงิน เพื่อป้องกันความเสียหายที่อาจเกิดขึ้น</span></p>
                            <div className={classes.videoWrapper}>
                                <ReactPlayer url="https://youtu.be/SdrUavyjewk" width="auto"  />
                                
                            </div>
                    </>);
    return (
        <>
            {render}
        </>
    )
}

export default Payments
