import React from 'react';

import {AiOutlineExclamationCircle} from 'react-icons/ai';
import classes from './PositiveIntInput.module.css';
const PositiveIntInput = (props) => {


  return (
    <>
      <input type="text"
       key= {`positiveinput_qty_${props.idPrefix}`}
       onClick={e => e.target.select()}
       onChange={ e => { props.inputChanged(e.target.value.replace(/\D/,'')) }}
       className={props.selectedClassName}
       value={props.val}
       />
       <span className={[classes.validator, (props.valid) ? 'd-none':''].join(' ')}>
       <AiOutlineExclamationCircle size={18}/>
       </span>
    </>
  );

}



export default PositiveIntInput;
