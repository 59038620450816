import React, { useState } from 'react';
import Image from '../../UI/Image/Image';
import classes from './BasketTile.module.css';
import numeral from 'numeral';

import { MdFavoriteBorder, MdFavorite } from 'react-icons/md';
import { AiOutlineDelete } from 'react-icons/ai';


import PositiveIntInput from '../../UI/Inputs/PositiveIntInput/PositiveIntInput';

const BasketTile = (props) => {

  const order = props.order;
  // const [wishAnimated, setWishAnimated] = useState(false);
  // const [deleteAnimated, setDeleteAnimated] = useState(false);


  // const wishClickedHandler = () => {

  //   setWishAnimated(true);
  //   setTimeout(function () {
  //     setWishAnimated(false);
  //     props.addToWish(order);
  //   }, 300);

  // }



  return (<>

    <div className={['row', classes.rowWrapper].join(' ')}>

      <div className="col-2">

        <div className={['d-flex', 'align-items-center', 'justify-content-center', 'h-100', classes.basketCheckboxWrapper].join(' ')}>
          <input disabled={!order.valid} key={`baskettile_checkbox_productselected_${props.idPrefix}`} type="checkbox" checked={order.checked} onChange={e => props.orderChecked(order.index)} />
        </div>

      </div>
      <div className="col-2">
        <div className={['d-flex', 'align-items-center', 'justify-content-center', 'h-100', classes.imageWrapper].join(' ')}>
          <Image key={`baskettile_image_${props.idPrefix}`} selectedClasses={[classes.productImage].join(' ')} src={order.image} name={order.selectedOptionName} />
        </div>
      </div>
      <div className="col-8">

        <div className="container">

          <div className="row">
            <div className="col-12">
              <div className={[classes.colWrapper, classes.firstColWrapper].join(' ')}>
                <span>{order.productName}</span>
              </div>
            </div>
            <div className="col-12">
              <div className={[classes.colWrapper]}>
                <span className={[classes.optionWarpper].join(' ')} title={order.selectedOptionName}>
                  {order.selectedOptionName}
                </span>
              </div>
            </div>

            <div className="col-12">
              <div className={[classes.colWrapper]}>
                

                <PositiveIntInput
                  idPrefix={props.idPrefix}
                  selectedClassName={[classes.inputQty].join(' ')}
                  inputChanged={number => props.qtyChanged(order.index, number)}
                  val={order.qty}
                  valid={order.valid}
                />

                <span className="d-none d-md-inline">&nbsp;&nbsp;{order.unit}</span>
              </div>
            </div>
            <div className="col-12">
              <div className={[classes.colWrapper]}>
                <span className={[(order.valid) ? '' : 'text-danger'].join(' ')}>คลัง {order.maxQty}</span><span className="d-none d-md-inline">&nbsp;&nbsp;{order.unit}</span>
              </div>
            </div>
            <div className="col-12">
              <div className={[classes.colWrapper]}>
                <span>ราคา {numeral(order.totalPrice).format('0,00.00')} บาท</span>
              </div>
            </div>
            <div className="col-12">
              <div className={[classes.colWrapper]}>
                <button onClick={() => props.addToWish(order) } className={[classes.btnOption].join(' ')}>
                  { (props.liked) ? <MdFavorite size={20} /> : <MdFavoriteBorder size={20} />} ถูกใจ
                                    </button>
                <button onClick={() => { props.deleteBasket(order.basketId) }} className={[classes.btnOption].join(' ')}>
                  <AiOutlineDelete size={20} /> ลบ
                </button>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

  </>);
}

export default BasketTile;
