import React from 'react';
import PropTypes from 'prop-types';
import './InstantSearch.css';
import './Search.css';

import Image from '../../components/UI/Image/Image';
import NotFoundImage from '../../assets/images/productnotfoundtransp.png';
// import NotFoundImage from 'https://dummyimage.com/1000x1000/000000/00ddff.png';

import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Stats,
  SortBy,
 
  connectStateResults
} from 'react-instantsearch-dom';

import { Link } from 'react-router-dom';

import algoliasearch from 'algoliasearch/lite';
import numeral from 'numeral';



const Search = () => {
  // applicationID , search-only api key
  const searchClient = algoliasearch(
    'BCS1PPO5G0',
    'ab05c442fd0166ab9e547046b68f7033'
  );
  const indexName = 'product_search_index';

  // const BrandFilter = connectStateResults(
  //   ({ searchState, searchResults, children }) =>
  //     searchResults && searchResults.nbHits !== 0 ? (
  //       children
  //     ) : (    
  //       <div></div>
  //     )
  // );

  // <div>{searchState.query} ไม่พบคำที่ต้องการค้นหา กรุณาลองคำค้นหาใหม่ เช่น ชื่อสินค้า ยี่ห้อสินค้า ประเภท หมวดหมู่ บาร์โค้ด รหัสสินค้า </div>
  const Results = connectStateResults(
    ({ searchState, searchResults, children }) =>
      searchResults && searchResults.nbHits !== 0 ? (
        children
      ) : (    
        <div className="row">
          <div className="col-12 px-0">
              {/* <div>ไม่พบคำที่ต้องการค้นหา กรุณาลองคำค้นหาใหม่ เช่น ชื่อสินค้า ยี่ห้อสินค้า ประเภท หมวดหมู่ บาร์โค้ด รหัสสินค้า</div>  */}
              <div class="alert alert-warning" role="alert">
              ไม่พบคำที่ต้องการค้นหา กรุณาลองคำค้นหาใหม่ เช่น ชื่อสินค้า ยี่ห้อสินค้า ประเภท หมวดหมู่ บาร์โค้ด รหัสสินค้า
              </div>
              <Image src={NotFoundImage} name="no products" selectedClasses="notFoundImage" />
              
          </div>
        </div>
      )
  );

  const PaginationResults = connectStateResults(
    ({ searchState, searchResults, children }) =>
      searchResults && searchResults.nbHits !== 0 ? (
        children
      ) : (
        <div></div>
      )
  );

  function Hit(props) {

          return (
        <Link to={`/products/${props.hit.objectID}`} className="link" key={props.hit.objectID}>
        <div className="row productWrapper">
            <div className="col-4 pr-0 col-md-3 col-lg-2">
              
                <div className="productImageWrapper">
                    <Image selectedClasses="productImage bg-dark" src={props.hit.image.src} name={props.hit.name} />
                </div>
              
            </div>
            <div className="col-8 col-md-9 col-lg-10">
                <div className="productDetailwrapper">
                      <h4>{props.hit.name}</h4>
                      <p className="productPrice">{numeral(props.hit.price).format('0,00.00')} บาท</p>
                      <p className="clamper-1"> {props.hit.brandName} / {props.hit.groupName} / {props.hit.typeName}</p>
                      <p className="clamper productDetail">{props.hit.searchDescription}</p>
                </div>
            </div>
        </div>
        </Link>
      );

  //  name / image / searchDescription  / price /groupName / typeName / brandName

    // console.log(props);
    
    // if(props.hit.show === true && props.hit.deleted === false){

    //   return (
    //     <Link to={`/products/${props.hit.objectID}`} className="link" key={props.hit.objectID}>
    //     <div className="row productWrapper">
    //         <div className="col-4 pr-0 col-md-3 col-lg-2">
              
    //             <div className="productImageWrapper">
    //                 <Image selectedClasses="productImage bg-dark" src={props.hit.image.src} name={props.hit.name} />
    //             </div>
              
    //         </div>
    //         <div className="col-8 col-md-9 col-lg-10">
    //             <div className="productDetailwrapper">
    //                   <h4>{props.hit.name}</h4>
    //                   <p className="">{numeral(props.hit.price).format('0,00.00')} บาท</p>
    //                   <p className="clamper-1"> {props.hit.brandName} / {props.hit.groupName} / {props.hit.typeName}</p>
    //                   <p className="clamper productDetail">{props.hit.searchDescription}</p>
    //             </div>
    //         </div>
    //     </div>
    //     </Link>
    //   );

    // }
    // else{
    //   return (<>
    //   <div className="d-none">

    //   </div>
    //   </>)
    // }

    
   

  }
  Hit.propTypes = {
    hit: PropTypes.object.isRequired,
  };



  return (
    <>
     <div className="ais-InstantSearch">
        
        <InstantSearch indexName={indexName} searchClient={searchClient}>
          
          <div className="container">

               <div className="row">

                    
                    <div className="col-12 col-xl-10 offset-xl-1 px-xl-0">
                      {/* right panel */}
                      <div className="container">
                        <div className="row">
                            <div  className="col-12 px-0">
                                  <SearchBox translations={{
                                      submitTitle: 'Submit your search query.',
                                      resetTitle: 'Clear your search query.',
                                      placeholder: 'ชื่อสินค้า / ยี่ห้อ / ประเภท / หมวดหมู่ ',
                                    }} />
                            </div>
                            <div  className="col-12 px-0">
                                <div className="d-flex justify-content-between stateWrapper">
                                  <Stats />
                                  <SortBy defaultRefinement={'product_search_index'} 
                                  items={[
                                    { label: 'สินค้าที่เกี่ยวข้อง', value: 'product_search_index' },
                                    { label: 'ราคาน้อยไปมาก', value: 'product_price_asc' },
                                    { label: 'ราคามากไปน้อย', value: 'product_price_desc' },
                                  ]} 
                                  />
                                </div>
                            </div>
                             
                        </div>
                          
                        <div className="product-hit-container"> 
                        <Results>
                            <Hits hitComponent={Hit} />
                        </Results>
                        </div>
                        <PaginationResults>
                            <Pagination showLast />
                        </PaginationResults>
                      </div>

                    </div>

               </div>
          </div>
        </InstantSearch>
        
      </div>  
    </>
  );
};

export default Search;