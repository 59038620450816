import React, { useState, useEffect } from 'react';
import classes from './PurchaseOrders.module.css';
import { db } from '../../utility/firebase/firebaseConnection';
import { useAuth } from '../../utility/firebase/AuthContext';
import FullScreenSpinner from '../../components/Spinner/FullScreenSpinner';
// import numeral from 'numeral';
import PurchaseOrder from '../../components/PurchaseOrder/PurchaseOrder';
import InfiniteScroll from "react-infinite-scroll-component";

import _ from 'lodash';
import Spinner from '../../components/Spinner/Spinner';
import { useHistory } from "react-router-dom";

// import no_purchase_history from '../../components/UI/NotFound/NoPurchase';
import NoPurchase from '../../components/UI/NotFound/NoPurchase';

const PurchaseOrders = (props) => {

    const [lastestDoc, setLastestDoc] = useState({});
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const { currentUser } = useAuth();
    const history = useHistory();

    useEffect(() => {

        async function loadPurchaseOrders(){

            try{
            
            const loadSize = 5;
            setLoading(true);
            const purchaseOrderData = await db.collection('PurchaseOrders')
                                        .where('userId', '==', currentUser.uid)
                                        .where('visible', '==', true)
                                        .orderBy("purchaseDate", "desc")
                                        .limit(loadSize)
                                        .get();
            // console.log('start loading.................',purchaseOrderData)

            if(!purchaseOrderData.empty){
                // console.log('has order')
                setLastestDoc(purchaseOrderData.docs[purchaseOrderData.size - 1]);
                setPurchaseOrders(purchaseOrderData.docs.map( (purchaseOrderDoc) => ( {docId:purchaseOrderDoc.id,...purchaseOrderDoc.data()} )));
            }
            // else{
            //     console.log('no orders')
            // }

            if(purchaseOrderData.size < loadSize){
                setHasMore(false);
            }

            }catch{

            }finally{
                setLoading(false);
            }
            

        }
        
        (currentUser) && loadPurchaseOrders();

    }, [currentUser]);


    function purchaseOrderClickedHandler(docID) {
        // console.log('handler',docID)
        history.push(`purchaseorderShow/${docID}`);
    }


    async function fetchPurchaseOrders() {


        const purchaseOrderData = await db.collection('PurchaseOrders')
                                        .where('userId', '==', currentUser.uid)
                                        .where('visible', '==', true)
                                        .orderBy("purchaseDate", "desc")
                                        .startAfter(lastestDoc || 0)
                                        .limit(5)
                                        .get();
        
        if(!purchaseOrderData.empty){

            setLastestDoc(purchaseOrderData.docs[purchaseOrderData.size - 1]);
            setPurchaseOrders( prev => {
                const newPurchaseOrders = purchaseOrderData.docs.map( (purchaseOrderDoc) => ( {docId:purchaseOrderDoc.id,...purchaseOrderDoc.data()} ));
                return prev.concat(newPurchaseOrders);
            });

        }
        else{
            setHasMore(false);
        }


    }

    let render = <FullScreenSpinner />;

    


    if (!loading) {
        if (purchaseOrders.length === 0) {
            render = (<>
            <div className={classes.noPurchaseWrapper}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8 offset-md-2">
                            <div style={{marginTop:'60px'}}>
                                <NoPurchase/>
                            </div>
                                
                        </div>
                    </div>
                </div>
                
            </div>
                    </>);
        }
        else {
            render = (<>
                <div className={classes.wrapper}>

                    <div className="container">
                        <div className="row mx-0">
                            <div className="col-12 mb-2">
                                <div className={classes.header}>
                                    <h4>
                                        ประวัติการสั่งซื้อ
                                    </h4>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="container">

                        {/* start */}
                        <InfiniteScroll

                            dataLength={purchaseOrders.length}
                            next={fetchPurchaseOrders}
                            hasMore={hasMore}
                            loader={<div className={classes.spinnerWrapper}><Spinner /></div>}
                        >
                            <div className="row mx-0">

                                {
                                    purchaseOrders.map(purchaseOrder => {
                                        return (<>
                                            <div className="col-12 col-lg-6">
                                                <div className={classes.purchaseOrderWrapper}>
                                                    <PurchaseOrder
                                                        PurchaseOrderClicked={(docID) => { purchaseOrderClickedHandler(docID) }}
                                                        docID={purchaseOrder.docId}
                                                        purchaseOrderNo={purchaseOrder.ID}
                                                        purchaseDate={purchaseOrder.purchaseDate}
                                                        lastestStatus={_.orderBy(purchaseOrder.statusItems, ['createdAt'], ['desc'])[0].name}
                                                        cancelled = {purchaseOrder.cancelled}
                                                        total={purchaseOrder.orders.reduce((sum, order) => sum + order.totalPrice, 0)}
                                                    />
                                                </div>
                                            </div>

                                        </>);
                                    })
                                }

                            </div>

                        </InfiniteScroll>

                        {/* end */}

                    </div>



                </div>

            </>);

        }
    }

    return (<>

        {render}


    </>);

}

export default PurchaseOrders;