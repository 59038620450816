import React from 'react';
import Payments from '../../components/payments/Payments';
import classes from './Howtopay.module.css';




const howtopay = (props) => {

  // const rowStyle = {marginTop:'20px'};

  return (<React.Fragment>
           
            <div className={classes.containerWrapper}>

                <div className="container">
                    <div className="row">
                      <div className="col">
                          <div className={classes.wrapper}>
                                <Payments />
                          </div>
                      </div>
                    </div>
                  </div>

            </div>
           
                
            
            
         </React.Fragment>);
}


export default howtopay;

