import React,{useEffect , useState } from 'react';
import { useWish } from '../../utility/products/WishContext';
import { db } from  '../../utility/firebase/firebaseConnection';
import ImageTile from '../../components/ProductItem/ImageTile/ImageTile';
import classes from './WishedProducts.module.css';
import FullScreenSpinner from '../../components/Spinner/FullScreenSpinner';
import NoWishes from '../../components/UI/NotFound/NoWishes';
import Store from '../../components/ProductItem/Store/Store';

import { useAuth } from '../../utility/firebase/AuthContext';

const WishedProducts = (props) => {

    const { wishes } = useWish();
    const [products , setProducts] = useState([]);
    const [loading, setLoading] = useState(true)

    const [userLevel, setUserLevel] = useState({ level: null });
    const { currentUser } = useAuth();

    useEffect(() => {

        if (currentUser) {

           
          //  load level to determine price, 
          db.collection('Users').doc(currentUser.uid).onSnapshot(userLevelDoc => {
            
            if (userLevelDoc.exists) {
             
              setUserLevel({
                level: userLevelDoc.data().level,
                ...userLevelDoc.data()
              })
            }
           
          });
        }else{

            setUserLevel({ level: null });
        }
    
    
      }, [currentUser])
    

    useEffect(() => {
        
        async function loadProducts(){

            const products = [];

            setLoading(true);
            try{
                console.log('start for loop')
                for(let i =0; i < wishes.length; i++){
                    const wish = wishes[i].data();
                    const productID = wish.productId;
                    // const optionIndex = wish.optionIndex;
                    const response = await db.collection("Products").doc(productID).get();
                 
                    const product = response.data();
                    products.push({
                        ...product,
                        productID: productID
                    });

                
                }
               
           
            }
            catch{
              
            }finally{
                setLoading(false);
                setProducts([...products]);   
            }


        }
        

        loadProducts();

    }, [wishes]);


    

    let render =  <FullScreenSpinner/>;

    if(!loading){

        if(products.length !== 0){

                render = (<>
                    <div className={classes.myContainer}>
                        <h2 style={{fontSize:'20px',paddingLeft:'20px'}}>สิ่งที่ถูกใจ</h2>
                        <div className="container">
                            <div className="row mx-0 px-0">
                           
                            {
                                
                                products.map( product => {
                                        
                                    return (<>
                                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mt-4 mx-0 px-0">
                                                <ImageTile
                                                productId = {product.productID}
                                                src = {product.image.src}
                                                name = {product.name}
                                                price = {product.price}
                                                qty = {product.qty}
                                                unit = {product.unit}
                                                memberPrices={product.memberPrices}
                                                userLevel = {userLevel}
                                                like = {true}
                                                />
                                        </div>

                                    </>)
                            })
                            }

                            </div>
                        </div>
                    </div>
                </>);

        }
        else{

            render = (<>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div style={{marginTop:'60px'}}>
                                <NoWishes/>
                            </div>
                                
                        </div>
                    </div>
                </div>
                </>);

        }
      
    }

    return (<>
                    {render}
                    <div style={{paddingTop:'30px'}} className="bg-danger">
                            <Store userLevel={userLevel} />
                    </div>
            </>);

}

export default WishedProducts;