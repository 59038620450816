import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

//  provider
import { AuthProvider } from "./utility/firebase/AuthContext";
import { BasketProvider } from "./utility/products/BasketContext";
import { WishProvider } from "./utility/products/WishContext";

// hoc
import Layout from './hoc/Layout/Layout';

// containers
import Basket from './containers/Basket/Basket';
import Products from './containers/Products/Products';
import ProductShow from './containers/Products/ProductShow';
import ProductGroup from './containers/Products/ProductGroup';

import PurchaseOrders from './containers/PurchaseOrders/PurchaseOrders';
import PurchaseOrderShow from './containers/PurchaseOrders/PurchaseOrderShow';
import Contact from './containers/Contact/Contact';
import Login from './containers/Account/Login/Login';
import Register from './containers/Account/Register/Register';
import Search from './containers/Search/Search';
import WishedProducts from './containers/WishedProducts/WishedProducts';

import HowtoPay from './containers/howtopay/Howtopay';

import Location from './containers/Account/Location/Location';
import CreateLocation from './containers/Account/Location/CreateLocation';
import ResetPassword from './containers/Account/Password/ResetPassword';

import Test from './containers/Test/Test';
import Numbar from './containers/Test/Numbar';
// import Test from './containers/Test/Test';

import ProductBrands from './containers/Products/ProductBrands';

import PrivateRoute from './hoc/PrivateRoute/PrivateRoute';

function App() {

  
  let routes = (
          <Switch key="OA7LLyLDZKhXDwVXCIhtEWgddC64F3Ic">
              <PrivateRoute key="cGwrGKmZKpE4KEgPalWLMTQ5IJsJS21y" path="/location" component={Location} />
              <PrivateRoute key="aJxmfVOB710MWkjlBTpFkzurgbKol9oV" path="/createLocation" component={CreateLocation} />
              <Route key="3EYqT75YJb3M5wanQAMSZT0snhEX86fr" path="/contact" component={Contact} />
              <Route key="dsadas2132154cxzcm!" path="/test" component={Test} />
              <Route key="231@12323cxzc4512xzSS" path="/numbar" component={Numbar} />
              <Route key="CR4vOCQo3ok7yt98tMfaCrHGmMVdhXmc" path="/login" component={Login} />
              <Route key="TtunvWIa9pjRwqBkhLY994VqNblUA9cy" path="/register" component={Register} />
              <PrivateRoute key="3k8qADGtDCzxisMuvKwJb6rksWaXnB9X" path="/basket" component={Basket} />
              <PrivateRoute key="hYXXMfv2rLlH3lm0CTK3fkLQRjgJEGjY" path="/wishes" component={WishedProducts} />
              <PrivateRoute key="KLG6kkePEpMAqbzW6kO3Be7KgOnHDdOo" path="/purchaseorders" component={PurchaseOrders} />
              <PrivateRoute key="8quXN38pw1TNpfWJboGkmBMKVwJ69VKL" path="/purchaseorderShow/:purchaseOrderId" component={PurchaseOrderShow} />
              <Route key="i6f21YjkWC3EQExOv9MGPMu6cSu718xf" path="/productgroup" component={ProductGroup} />
              <Route key="02aNF7j8PfzxCN2irxEDhK54PpzjZlsA" path="/search" component={Search} />
              <Route key="OYyeBfm59q83VYNCWCwIEpKWZc36kQWi" path="/products/:productId" component={ProductShow} />

              <Route key="6eXI7vSsq332dtLiDDJ17qQlRbq3XM1E" path="/productBrand/:brandId/:brandName" component={ProductBrands} />

              <Route key="6tMuy0eUMOIww5Ncr0rpOItretcBUIEy" path="/howtopay" component={HowtoPay} />
              <Route key="UonFWA01KibXoj2GFn2N1ujBlP6ntbUJ" path="/resetpassword" component={ResetPassword} />
              <Route key="O9A9AaCnyGGuAvlyCpZafcrdCeJCXUch" path="/" exact component={Products} />
              <Redirect key="gc74i0CI4EMX2UkGp3S79x9tEcuIJ5BW" to="/" />
          </Switch>
        );

  return (
      <React.Fragment>
        <AuthProvider key="KDFVkWK7yUBgFgbgZ582Wl92U9cjl4bU" >
          <BasketProvider key="TamrbR3OgKaCMuZoINFBJi44i7C0sKK0" >
            <WishProvider key="5t5r6wNNlgmHYy7G3dKrLPKVFPDbXBcx">
                  <Layout key="xDlnwiRYuM8EV9oRqMbqZBNVmDeePSRN">
                      {routes}
                  </Layout>
            </WishProvider>
          </BasketProvider>
        </AuthProvider>
      </React.Fragment>
  );
}

export default withRouter(App);
