import React from 'react';
import { Modal, Button } from 'react-bootstrap';



const ConfirmModal = (props) => {

  return (
    <>
      <Modal 
        show={props.show} 
        style={props.selectedStyle}  
      > 
        <Modal.Header>
          <Modal.Title style={{'fontSize':'20px'}}>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.message}</Modal.Body>
        <Modal.Footer>
         <Button onClick={ () => props.confirmModal(true) } variant="success">
            ยืนยัน
          </Button>
          <Button onClick={ () => props.confirmModal(false)} variant="danger">
            ยกเลิก
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );

}


export default ConfirmModal;
