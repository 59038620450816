import React,{ useState,useEffect } from 'react'
import Async from './Async';

function Test() {

  const [showText, setShowText] = useState(false);

  

  const changeTextHandler = (e) => {
    
    // setShowText( prev => !prev);
    setShowText(true);
  }

  return (
    <>
    <div>
      { !showText && <p>show text is false</p>}
      { showText && <p>show text is true</p>}
      <button onClick={(e)=> { changeTextHandler(e)}}>change</button>
    </div>

    <div>
          <Async/>
    </div>
    </>
  )
}

export default Test
