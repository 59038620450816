import React,{ useState,useEffect } from 'react';
import Store from '../../components/ProductItem/Store/Store';
import CreatedAt from '../../components/ProductItem/CreatedAt/CreatedAt';
import ProductSliders from '../../components/UI/Sliders/ProductSliders/ProductSliders';

import { useAuth } from '../../utility/firebase/AuthContext';
import { db } from '../../utility/firebase/firebaseConnection';

import Brands from '../Brands/Brands';

const Products = (props) => {

  const { currentUser } = useAuth();
  const [userLevel, setUserLevel] = useState({ level: null });

  useEffect(() => {

    if (currentUser) {
      //  load level to determine price, 
      db.collection('Users').doc(currentUser.uid).onSnapshot(userLevelDoc => {

        if (userLevelDoc.exists) {
          setUserLevel({
            level: userLevelDoc.data().level,
            ...userLevelDoc.data()
          })
        }
      });
    }
    else{
      setUserLevel({ level: null });
    }
  }, [currentUser])

  return (
        <React.Fragment>

          <div style={{marginTop:'56px'}}>
              <ProductSliders />
          </div>

          <div style={{marginTop:'40px'}}>
            <CreatedAt userLevel={userLevel} />
          </div>

          <div style={{marginTop:'40px'}}>
            <Brands />
          </div>
          
            <div style={{marginTop:'30px'}}>
                <Store userLevel={userLevel} />
            </div>
        </React.Fragment>
  );
}
export default Products;
