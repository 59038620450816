import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import logo from '../../../assets/images/racewing-white.png';
import classes from './Login.module.css';
import NavigationItem from '../../../components/Navigation/NavigationItem/NavigationItem';
import { useAuth } from '../../../utility/firebase/AuthContext';
import { useHistory } from "react-router-dom";
import AlertBar from '../../../components/UI/AlertBar/AlertBar';
import FullScreenSpinner from '../../../components/Spinner/FullScreenSpinner'

import { useLocation } from "react-router-dom";
import _ from 'lodash';
import Recaptcha from 'react-recaptcha';

const Login = (props) => {

  const history = useHistory();
  const { login } = useAuth();
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const email = useRef();
  const password = useRef();
  const location = useLocation();

  const [robotChecked, setRobotChecked] = useState(false);

  useEffect(()=>{

    document.body.style.backgroundColor = '#d34444';
    return (()=>{
        document.body.style.backgroundColor = '#f0f0f0';
    });
  },[]);

  const loginHandler = (e) => {

      e.preventDefault();


      if(!robotChecked){

        setError('กรุณายืนยันตัวตน')

      }
      //  test mode
      // if(false){

      //   setError('กรุณายืนยันตัวตน')

      // }
      else{
        setError('');
        setLoading(true);
        login(email.current.value,password.current.value)
        .then( response => {
  
            const nextTarget = _.has(location,"state.nextTarget") ? props.location.state.nextTarget : '/';
            history.push(nextTarget);
  
        }).catch( error => {
            setError(`${error.message}`);
        });
  
        setLoading(false);

      }
    
  }

  const verifyCallbackHandler = (response) =>{
     
      if(response){
        setRobotChecked(true);
      }
  }

  let render = <FullScreenSpinner/>;

  if(!loading){
      render = (
        <div className="d-flex align-items-center" style={{height:'100vh'}}>

            <Container className={[classes.container].join(' ')} fluid>

                <Row>

                  <Col className="d-none d-lg-block col-lg-6">
                       <div className={[classes.logoWrapper,'d-flex','align-items-center','justify-content-center'].join(' ')}>
                         <img src={logo} alt="racwing-white-logo" />
                       </div>
                   </Col>

                    <Col className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-0">

                    <Form onSubmit={loginHandler} className={[classes.formWrapper,'bg-white mx-auto'].join(' ')} style={{borderRadius:'8px',padding:'60px 30px'}} >
                      <h3 className="text-center">ลงชื่อเข้าใช้งาน</h3>
                      <br/>
                      { (error) && (<AlertBar>{error}</AlertBar>)}

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>อีเมล:</Form.Label>
                                <Form.Control type="email" ref={email} />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>รหัสผ่าน:</Form.Label>
                                <Form.Control type="password" ref={password}/>
                            </Form.Group>
                            <Form.Group controlId="catchpa">
                             
                              <Recaptcha
                                  sitekey="6LdO_8EaAAAAABeNJ-sdxFiNhyVDG6ewvEsG4hVj"
                                  render="explicit"
                                  verifyCallback={ (response) => {verifyCallbackHandler(response)}}
                              
                                />
                              
                                
                            </Form.Group>
                            <br/>
                            <Button className="mb-2" type="submit" style={{backgroundColor:'#3b3b3b',color:'white',fontWeight:400,width:'100%'}}>
                                เข้าใช้งาน
                            </Button>
                            <br/>
                            <Form.Label htmlFor="inlineFormInputName2">
                             ท่านสามารถสมัครสมาชิกได้ที่นี่ <NavigationItem link="/register">สมัครบริการ</NavigationItem>
                           </Form.Label>
                           <Form.Label htmlFor="inlineFormInputName2">
                             ลืมรหัสผ่าน/แก้ไขรหัสผ่าน <NavigationItem link="/resetpassword">ที่นี่</NavigationItem>
                           </Form.Label>
                      </Form>

                    </Col>
                </Row>
            </Container>

          </div>
        );
  }

  return (<>
                {render}

          </>);

}


export default Login;
