import React, { Component } from 'react';
import Topbar from '../../components/Navigation/Topbar/Topbar';
import SideBar from '../../components/Navigation/SideBar/SideBar'
import Backdrop from '../../components/UI/Backdrop/Backdrop';

class Layout extends Component {

  

  componentDidMount(){
    document.body.style.backgroundColor = '#f0f0f0';
  }

  state = {
        showSideDrawer: false,
        key_prefix: "U0CurrxLZ3Q7o6KFrNliA5lBBAo9utiJ"
  }

   toggleSidebar = () => {
    this.setState( (prevState) => {
        return {
            showSideDrawer: !prevState.showSideDrawer,
  
        }
      });
    // console.log(this.state.showSideDrawer);
  }


    render () {
        return (
            <React.Fragment>
                  <Topbar clicked={this.toggleSidebar} />
                  <SideBar clicked={this.toggleSidebar} open={this.state.showSideDrawer} />
                  <Backdrop backdropClicked={ ()=> this.toggleSidebar()} show={this.state.showSideDrawer} />
                  {this.props.children}
            </React.Fragment>
        )
    }
}


export default Layout;
