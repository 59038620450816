import firebase from 'firebase/app';
import "firebase/auth";
import 'firebase/firestore';


import 'firebase/storage';

//production mode
const firebaseConnection = firebase.initializeApp({
    apiKey: "AIzaSyDBxOs35X7ZeAWNsUSSWwMoDni27Rke3Kg",
    authDomain: "racewing-37ceb.firebaseapp.com",
    databaseURL: "https://racewing-37ceb-default-rtdb.firebaseio.com",
    projectId: "racewing-37ceb",
    storageBucket: "racewing-37ceb.appspot.com",
    messagingSenderId: "677524093038",
    appId: "1:677524093038:web:3921aa2eec3f110f380e7f"
  })

// // //dev mode
// const firebaseConnection = firebase.initializeApp({
//   apiKey: "AIzaSyBcNABbJXkWlLvVTFbKA0Y0ijS7MuVxXzg",
//   authDomain: "racewing-development.firebaseapp.com",
//   projectId: "racewing-development",
//   storageBucket: "racewing-development.appspot.com",
//   messagingSenderId: "112556623878",
//   appId: "1:112556623878:web:4012d06226d0404e035b87"
// });


export const db = firebase.firestore();
export const firebaseAuth = firebaseConnection.auth()
export const storage = firebase.storage();
// export const storageRef = firebase.storage().ref();
// export default firebaseConnection

export default firebaseConnection
