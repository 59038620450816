import React from 'react';
import _ from 'lodash';
import classes from './NumBar.module.css';


const NumBar = ( props ) => {


  return (
    <>
    <div className={['d-flex','justify-content-center',classes.numbarWrapper].join(' ')}>
            <button disabled={props.qty <= props.min || _.isNaN((_.toNumber(props.qty))) || (props.qty === "") } className="flex-fill" onClick={() => props.qtyChanged(-1,true)} > - </button>
            <input
                
                  type="text" value={props.qty}
                  onChange={ e => props.qtyChanged(e.target.value,false)}
                  onClick={ e => e.target.select() }
            />
            <button disabled={props.qty >= props.max || _.isNaN((_.toNumber(props.qty))) || (props.qty === "") } className="flex-fill" onClick={() =>props.qtyChanged(1,true)} > + </button>
    </div>
    </>);

}


export default NumBar;
