import React from 'react';
import { Alert } from 'react-bootstrap';
import uuid from 'react-uuid';

const AlertBar = (props) => {
  return (
    <Alert key={uuid()} variant='danger'>{props.children}</Alert>
  );
}

export default AlertBar;
