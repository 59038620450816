import React from 'react';
import { FaPhoneAlt,FaMapMarkerAlt } from "react-icons/fa";
import { HiMailOpen } from "react-icons/hi";
import { AiFillClockCircle } from "react-icons/ai";
// import qrcode from '../../../assets/images/qr_code.png';
import classes from './Address.module.css';

const address = (props) => {

return (
        <>
          <div className="d-flex flex-column h-100 justify-content-center">
              <h3 className={[classes.headWrapper,'mt-4','mt-lg-0'].join(' ')}>บริษัท ไทย ออโตโมทีฟ พาร์ท จำกัด</h3>
              <div className={[classes.lineWrapper].join(' ')}>
                  <FaMapMarkerAlt size={20}/>&nbsp;&nbsp;&nbsp;เลขที่ 291 ถนนบ้านดอนตูม บ้านโป่ง บ้านโป่ง ราชบุรี 70110
              </div>
              <div className={[classes.lineWrapper].join(' ')}>
                  <FaPhoneAlt size={20}/>&nbsp;&nbsp;&nbsp;<span>099-349-6296</span>
              </div>

              <div className={[classes.lineWrapper].join(' ')}>
                  <HiMailOpen size={20} /> &nbsp;&nbsp;&nbsp;<span>redwing@gmail.com</span>
              </div>

              <div className={[classes.lineWrapper].join(' ')}>
                  <AiFillClockCircle size={20} /> &nbsp;&nbsp;&nbsp;<span>วันจันทร์ - วันอาทิตย์ 08:00 - 17:00</span>
              </div>
          </div>
        </>
);

}


export default address;
