import React, { useEffect, useState } from 'react';
import ImageTile from '../../components/ProductItem/ImageTile/ImageTile';
import { db } from '../../utility/firebase/firebaseConnection';
import { useLocation } from "react-router-dom";
import _ from 'lodash';
import classes from './ProductGroup.module.css';

import FullScreenSpinner from '../../components/Spinner/FullScreenSpinner';
import ProductNotFound from '../../components/UI/NotFound/ProductNotFound';

import { useAuth } from '../../utility/firebase/AuthContext';
const ProductGroup = (props) => {

    const [products, setProducts] = useState([]);

    const [loading, setLoading] = useState(false);


    const { currentUser } = useAuth();
    const [userLevel, setUserLevel] = useState({ level: null });

    const defaultCategory = {
        groupId: null,
        groupName: null,
        typeId: null,
        typeName: null
    };

    const [category, setCategory] = useState(defaultCategory);
    const location = useLocation();


    useEffect(() => {

        if (currentUser) {
          //  load level to determine price, 
          db.collection('Users').doc(currentUser.uid).onSnapshot(userLevelDoc => {
    
            if (userLevelDoc.exists) {
              setUserLevel({
                level: userLevelDoc.data().level,
                ...userLevelDoc.data()
              })
            }
          });
        }
        else{
          setUserLevel({ level: null });
        }
      }, [currentUser])

    useEffect(() => {

        //  get groupId and product Id from props 
        const category = !_.isEmpty(location, 'state.category') ? props.location.state.category : defaultCategory;


        setCategory(category);

    }, [location]);

    useEffect(() => {

        // console.log('loaded', category);

        let unsubscribe;
        if (category.groupId && category.typeId) {
            // console.log('group id', category.groupId)
            // load product where group id == category.groupid and category.typeId 
            // use array field

            // console.log('loaded', category);
            setLoading(true);
            unsubscribe = db.collection('Products')
                .where('groupId', '==', category.groupId)
                // .where('typeId','==',category.typeId)
                .onSnapshot((productData) => {

                    
                    const elements = productData.docs.map(productDoc => {
                        return ({
                            docId: productDoc.id,
                            ...productDoc.data()
                        })
                    }).filter(product => product.show === true && product.deleted === false);
                    
                    setProducts(elements);
                    // setProducts(productData.docs.map(productDoc => {
                    //     return ({
                    //         docId: productDoc.id,
                    //         ...productDoc.data()
                    //     })
                    // }));
                    setLoading(false);
                });

        }

        return () => {
            setLoading(false);
            unsubscribe && unsubscribe();
        }

    }, [category])


    // console.log('category docs', category);


    let render = <FullScreenSpinner />;

    if (!loading) {

        const otherProducts = products.filter(product =>{
            return (product.groupId === category.groupId) && (product.typeId !== category.typeId);
        });

        let render_others = (<>
        
        </>);

        if(otherProducts.length > 0){
            render_others = (<>
                <h2 style={{ fontSize: '20px', paddingLeft: '10px',paddingBottom:'20px',paddingTop:'20px' }}>{`สินค้าในกลุ่ม ${category.groupName}`}</h2>
                <div className="row mx-0 px-0">
                
                    {
                    otherProducts.map( otherProduct => {
                        return (<>
                        
                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-4 mx-0 px-0">
                                <ImageTile 
                                productId={otherProduct.docId} 
                                discount={otherProduct.discount} 
                                price={otherProduct.price} 
                                src={otherProduct.image.src} 
                                alt={otherProduct.name}
                                userLevel={userLevel}
                                memberPrices={otherProduct.memberPrices}
                                 name={otherProduct.name} />
                        </div>

                        </>);
                    })
                    }

                </div>
            </>);
        }



        if (products.length) {
            render = (<>
                <div className={classes.wrapper}>
                    
                    <div className="container">
                        <h2 style={{ fontSize: '20px', paddingLeft: '10px','paddingBottom':'20px' }}>{`สินค้ากลุ่ม ${category.groupName} > ${ category.typeName}`}</h2>
                        <div className="row mx-0 px-0">

                            {
                                products.filter(product => (product.groupId === category.groupId) && (product.typeId === category.typeId)).map(({ docId: Id, ...product }) => {

                                    return (
                                        <>
                                            <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-4 mx-0 px-0">
                                                <ImageTile 
                                                productId={Id} 
                                                discount={product.discount} 
                                                price={product.price} 
                                                src={product.image.src} 
                                                alt={product.name} 
                                                userLevel={userLevel}
                                                memberPrices={product.memberPrices}
                                                name={product.name} />
                                            </div>
                                        </>
                                    );
                                })
                            }
                        </div>

                        {render_others}
                        

                    </div>
                </div>
            </>);
        }
        else {
            render = (<>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-10 offset-md-1">
                            <ProductNotFound />
                        </div>
                    </div>
                </div>


            </>);
        }

    }



    return (<>
        {render}
    </>);

}


export default ProductGroup;