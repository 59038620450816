
import React from 'react';
import classes from './PurchaseOrder.module.css';
import numeral from 'numeral';
import moment from 'moment';


const PurchaseOrder = (props) => {

    // console.log('cancelled', props.cancelled)
    return (<>
    <div className={classes.purchaseOrderWrapper}>

                <table>
                    <tbody>
                        <tr>
                            <td>
                                    หมายเลข
                            </td>
                            <td>
                                    {props.purchaseOrderNo}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                    วันที่
                            </td>
                            <td>
                                    {moment(props.purchaseDate).format('DD/MM/YYYY HH:mm')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                    สถานะ
                            </td>
                            <td>
                                    {props.lastestStatus}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                    ยอดรวม
                            </td>
                            <td>
                                <span className={[(props.cancelled) && (classes.cancelled) ].join(' ')}>
                                    &#3647;{numeral(props.total).format('0,00.00')}
                                </span>
                                {(props.cancelled) && (<><span> ยกเลิก</span></>)}
                            </td>
                        </tr>

                    </tbody>
                </table>

                <div className={classes.btnSlipWrapper}>
                    <button className="btn btn-danger btn-block" onClick={ () => {props.PurchaseOrderClicked(props.docID)} }>
                        ดูรายละเอียด / แนบสลิป
                    </button>
                </div>

    </div>
   
    </>);
}

export default PurchaseOrder;
