import React from 'react';
import Image from '../../../UI/Image/Image';
import classes from './MenuItem.module.css';
import DOMPurify from 'dompurify'
import arrowDown from '../../../../assets/images/arrow_down.svg';

const MenuItem = (props) => {

  return (
    <div className={[classes.menuItemWrapper,(props.show)? classes.menuItemWrapperHighLight:''].join(' ')} onClick={()=> props.menuItemClicked() }>
        <button className={[classes.menuButton,classes.clamper].join(' ')} >
              {
                (props.hasImage) &&
                  <Image selectedClasses={[classes.imgIcon].join(' ')} src={props.icon} name={props.name}/>
              }
              <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.name)}}></span>
              {
                (props.hasArrow)&&
                  <Image selectedClasses={[classes.arrow].join(' ')} src={arrowDown} name="sidebar_arrow"/>
              }
        </button>
    </div>

  );
}

export default MenuItem;
