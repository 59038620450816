import React,  { useContext, useState, useEffect }  from "react"
import { db } from  '../../utility/firebase/firebaseConnection';
import _ from 'lodash';



const WishContext = React.createContext();

export function useWish() {
  return useContext(WishContext)
}




export function WishProvider({ children }) {

    const [ wishes, setWishes ] = useState([]);
    const [ user, setUser ] = useState();
    // const [ wishAnimated, setWishAnimated ] = useState(false);

    function setUserWish(currentUser){
       setUser(currentUser);
    }


    async function addToWishList({userId,email,productId}){

       

          const wishRef =  await db.collection('Wishes')
                              .where('productId','==', productId)
                              // .where('optionIndex','==', selectedOptionIndex)
                              .where('userId','==',userId)
                              .get();

        //  if exist remove // if not exist add
         if(!wishRef.empty){
            const productInWishId = wishRef.docs[0].id;
            const productInwish = wishRef.docs[0].data();
            await db.collection('Wishes').doc(productInWishId).delete();

         }else{
            await db.collection('Wishes').add({
              userId:userId,
              email: email,
              productId:productId
            });
         }
    }


    useEffect(()=>{
        // rerender
        const unsubscribe = db.collection("Wishes")
                        .where('userId','==', !_.isEmpty(user) ? user.uid : 0 )
                        .onSnapshot((wishData) => {
                            setWishes(wishData.docs);
                        });

        return ()=>{
          (unsubscribe) && unsubscribe();
        }
    },[user]);



    const value = {
      wishes,
      setUserWish,
      addToWishList
    }

    return (
      <WishContext.Provider  value={value}>
        { children}
      </WishContext.Provider>
    )


}
