import React, { useContext, useState, useEffect } from "react"
import { firebaseAuth } from "./firebaseConnection"

import { db } from "./firebaseConnection";



const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userLoading, setUserLoading ] = useState(true);
  const [userSpending, setUserSpending] = useState(0);

  const key_prefix = "C1NYWyQlEzLs2Si93QvM5husIuf6SXn3";


  async function signup(email, password) {
    return await firebaseAuth.createUserWithEmailAndPassword(email, password)
  }

  async function login(email, password) {
    return await firebaseAuth.signInWithEmailAndPassword(email, password)
  }

  async function logout() {
    return await firebaseAuth.signOut()
  }

  async function resetPassword(email) {
    return await firebaseAuth.sendPasswordResetEmail(email)
  }

  async function updateEmail(email) {
    return await currentUser.updateEmail(email)
  }

  async function updatePassword(password) {
    return await currentUser.updatePassword(password)
  }

  async function sendPasswordResetEmail(email) {
    return await firebaseAuth.sendPasswordResetEmail(email);
  }





  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(user => {

      setCurrentUser(user)
      setUserLoading(false);
    })

    return unsubscribe;
  }, [])

  useEffect(()=> {

    if(currentUser){
      // var startDate = new Date();
      // startDate.setMonth(startDate.getMonth() - 3);
      // console.log('3 months ago',startDate.toLocaleDateString());
      // var startDateUnix = startDate.getTime()/1000;
      // console.log('3 months ago in Unix', startDateUnix);
      // setUserSpending(30000)

       const date = new Date();
       const startDate = new Date(date.getFullYear(), date.getMonth() - 1 , 1);
      //  console.log('3 months ago',startDate.toLocaleDateString());
       const startDateUnix = startDate.getTime()/1000;
       const userId = currentUser.uid;

      //  console.log('3 months ago',startDate);

       db.collection('PurchaseOrders')
       .where('userId','==',userId)
       .where('cancelled','==',false)
       .where('purchaseDate','>=',startDateUnix)
       .onSnapshot(PurchaseOrderDoc => {
       
        const userHasSpent = PurchaseOrderDoc.docs.reduce( (spentTotal,purchaseOrderDoc) => {
          // console.log('purchasedate' , purchaseOrderDoc.data().purchaseDate);
          return spentTotal + purchaseOrderDoc.data().orders.reduce((total,order) => total +  (order.price * order.qty), 0)
            // return purchaseOrderDoc.data().orders.reduce((total,order) => total +  order.totalPrice, 0)
        }, 0 )
        setUserSpending(userHasSpent);
        });

       


    }

  }, [currentUser]);


  


  // console.log('user spendsing')


  const value = {
    currentUser,
    userSpending,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    sendPasswordResetEmail
  }

  return (
    <AuthContext.Provider key={`${key_prefix}_authcontext_provider`} value={value}>
      { !userLoading && children}
    </AuthContext.Provider>
  )
}
