import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Barcode from '../../components/Contact/Barcode/Barcode';
import Map from '../../components/Contact/Map/Map';
import Address from '../../components/Contact/Address/Address';

import classes from './Contact.module.css';

const contact = (props) => {

  // const rowStyle = {marginTop:'20px'};

  return (<React.Fragment>
          <Container>

          <Row className={[classes.firstRow].join(' ')}>
            <Col>
                <Map />
            </Col>
          </Row>

            <Row className={classes.secondRow}>
                <Col lg={6} className="order-2 order-lg-1 mt-4 mt-lg-0">
                    <Barcode />
                </Col>
                <Col lg={6} className="order-1 order-lg-2 mb-4 mb-lg-0">
                    <Address />
                </Col>
            </Row>


          </Container>
</React.Fragment>);
}


export default contact;
