import React from 'react';
import classes from './Spinner.module.css';


const spinner = () => {
  return (
    <div className={classes.lds_dual_ring}></div>
  );
}

export default spinner;
