
import React, { useEffect, useState, useRef } from 'react';

import { useParams } from "react-router-dom";

import { db, storage } from '../../utility/firebase/firebaseConnection';

import classes from './PurchaseOrderShow.module.css';
import moment from 'moment';
import numeral from 'numeral';

import _ from 'lodash';
import FullScreenSpinner from '../../components/Spinner/FullScreenSpinner';
import Spinner from '../../components/Spinner/Spinner';

import defaultImage from '../../assets/images/add-image.svg';

import {Link} from "react-router-dom";

import ConfirmModal from '../../components/UI/Modal/ConfirmModal';


const PurchaseOrderShow = (props) => {

    const [purchaseOrder, setPurchaseOrder] = useState();
    const { purchaseOrderId } = useParams();

    const [showConfirmModal, setShowConfirmModal] = useState(false);


    // const [loading, setLoading] = useState(false);
    // const [showAlert, setshowAlert] = useState(false);

    const [uploadedImage, setUploadedImage] = useState({
        selectedFile: null,
        base64: defaultImage,
        success: false,
        link: null,
        loading: false,
        touched: false,
        error: null
    });

    var storageRef = storage.ref();

    const fileInput = useRef();

    useEffect(() => {

        const unsubscribe =  db.collection('PurchaseOrders')
                            .doc(purchaseOrderId)
                            .onSnapshot(purchaseOrderData => {

                                if (purchaseOrderData.exists) {
                                    // console.log('exist')
                                    const purchaseOrder = purchaseOrderData.data();

                                    // console.log('has transportation images', (purchaseOrder.transportationImages.length !== 0) );
                                    setPurchaseOrder({ 
                                        ...purchaseOrder
                                        , docId: purchaseOrderData.id
                                        , hasTrasportationImages : (purchaseOrder.transportationImages.length !== 0)
                                        , hasReceipt:_.has(purchaseOrder,'receiptImage') 
                                    });
                                   
                                
                                }
                                

                            });
                    
        return unsubscribe;
    }
        , [purchaseOrderId]);


    async function fileChagedHandler(e) {


        if (e.target.files[0]) {
            const file_base64 = await getBase64(e.target.files[0]);
            // console.log(file_base64);

            setUploadedImage({
                selectedFile: e.target.files[0],
                base64: 'data:image/png;base64,' + file_base64,
                success: false,
                link: null,
                loading: false,
                touched: false,
                error: null
            })
        }

    }
    function clearSelectedImage() {
        setUploadedImage({
            selectedFile: null,
            base64: defaultImage,
            success: false,
            link: null,
            loading: false,
            touched: false,
            error: null
        })
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                if ((encoded.length % 4) > 0) {
                    encoded += '='.repeat(4 - (encoded.length % 4));
                }
                resolve(encoded);
            };
            reader.onerror = error => reject(error);
        });
    }

    function uploadHadler() {

        setUploadedImage(prev => ({...prev,loading:true}));
        const file = uploadedImage.selectedFile;
        const timeStamp = new Date().getTime();
        const rndNo = Math.floor(Math.random() * 10000001);
        const name = timeStamp + '_' + rndNo + '_' + file.name.replace(/\s/g, '_');
        var imagesRef = storageRef.child(`Receipts//${name}`);

        var uploadTask = imagesRef.put(file);


        uploadTask.on('state_changed',
            (snapshot) => {
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                // Handle unsuccessful uploads
                // setLoading(false);
                setUploadedImage(prev => {
                    return ({
                        ...prev,
                        success: false,
                        link: null,
                        loading:false,
                        touched: true,
                        error: error
                    });
                });
                
            },
            () => {
                // setLoading(false);
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    
                    // console.log('File available at', downloadURL);

                    setUploadedImage(prev => {
                        return ({
                            ...prev,
                            success: true,
                            link: downloadURL,
                            loading:false,
                            touched: true
                        });
                    });

                    // 
                    db.collection('PurchaseOrders').doc(purchaseOrderId).update({
                        receiptImage: {
                            name: name,
                            link:downloadURL
                        },
                        statusItems: [...purchaseOrder.statusItems,
                            {
                                name: 'ได้รับการแจ้งโอนเงิน / รอตรวจสอบ',
                                comments: 'กรุณารอตรวจสอบสลิปธนาคาร',
                                createdAt : new Date().getTime(),
                                default: false,
                                visible: true,
                                docId: 'T1TxpqWZanIEY5elRNx5'
                            }
                        ]
                    }).then(()=>{
                        console.log('success');
                    }).catch(error =>{

                        setUploadedImage(prev => {
                            return ({
                                ...prev,
                                success: false,
                                error: error
                            });
                        });

                    });

                    
                });
            }
        );
    }

    function showConfirmModalHandler(){
        setShowConfirmModal(true);
    }

    function cancelPurchaseOrderHandler(confirm){
        //  show modal to confirm 
        setShowConfirmModal(false);

        //  if yes execute the function
        // setShowConfirmModal(true);
        if(confirm){
            // console.log('cancel confirm',purchaseOrder);

            const docId = purchaseOrder.docId;

            db.collection('PurchaseOrders').doc(docId).update({
                cancelled: true,
                statusItems: [...purchaseOrder.statusItems,
                    { 
                        docId:'WBai4xmNwlQf2ntUKjZh',
                        name :'ยกเลิกโดยผู้ซื้อ' , 
                        comment: "ถูกยกเลิกโดยลูกค้า", 
                        default:false,
                        visible:true ,
                        createdAt:new Date().getTime()
                    }
                ]
            })

        }

    }
   


    let render = <><FullScreenSpinner /></>;

    if (purchaseOrder) {



        render = (<>
            <div className={classes.wrapper}>
                <div className="container">

                    {(uploadedImage.touched) && (
                        (uploadedImage.success) ? (<>
                            <div className="row">
                                <div className="col-12 col-lg-8 offset-lg-2">
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        อัปโหลดสำเร็จ ทางเราจะรีบจัดส่งสินค้าให้หลังจากที่ตรวจสอบหลักฐานเรียบร้อยแล้ว
                                    <button type="button" onClick={()=>{ setUploadedImage(prev => ({...prev,touched:false})) } } className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>) :(<>
                                <div className="row">
                                    <div className="col-12 col-lg-8 offset-lg-2">
                                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            เกิดข้อผิดพลาดขึ้นระหว่างอัปโหลด กรุณาลองใหม่
                                <button type="button" className="close" onClick={()=>{ setUploadedImage(prev => ({...prev,touched:false})) } } data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                        </>)
                    )}

                  
                    <div className="row">
                        <div className="col-12 col-lg-8 offset-lg-2">

                            <div className={classes.PurchaseOrderShowWrapper}>

                                <div className="d-flex justify-content-between">
                                    <div>
                                        หมายเลข {purchaseOrder.ID}
                                    </div>
                                    <div>
                                        {moment(purchaseOrder.purchaseDate).format('DD/MM/YYYY HH:mm')}
                                    </div>
                                </div>

                                <table>
                                    <tbody>
                                        {

                                            purchaseOrder.orders.map((order, index) => {

                                                return (<>
                                                    <tr>
                                                        <td style={{ paddingLeft: '20px' }}>
                                                            {index + 1}. {order.productName}
                                                        </td>
                                                        <td className="text-right pr-2">
                                                            x {order.qty}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ paddingLeft: '20px' }}>
                                                            <div className="text-muted">
                                                                {numeral(order.discount).format('0,00.00')} / {order.unit}
                                                            </div>
                                                        </td>
                                                        <td className="text-right pr-2">
                                                            {numeral(order.totalPrice).format('0,00.00')}
                                                        </td>
                                                    </tr>
                                                </>);
                                            })
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td className="text-right pr-2">
                                                ยอดรวม
                                            </td>
                                            <td colSpan="2" className="text-right pr-2">
                                                {numeral(purchaseOrder.orders.reduce((sum, order) => sum + order.totalPrice, 0)).format('0,00.00')}
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>

                                <hr className={classes.sectionDivider} />

                            </div>

                        </div>
                    </div>

                    {/* attach receipt session */}
                    <div className="row">
                        <div className="col-12 col-lg-8 offset-lg-2">
                            <div className={classes.attachReceiptContentWrapper}>

                               
                                {
                                (!purchaseOrder.cancelled) ? (
                                    <>
                                     <div className="d-flex justify-content-between">
                                    <div>แนบเอกสารชำระเงิน</div>
                                    <div>
                                        <Link to="/howtopay">วิธีการชำระเงิน </Link>
                                    </div>
                                    </div>
                                    </>
                                ): (<>
                                <div className="text-danger text-center">รายการถูกยกเลิก</div>
                                </>)
                                    
                                }
                                    
                                

                                
                                { /* if receipt image exist , don't bother rendering */ }
                                { (!purchaseOrder.hasReceipt) && (!purchaseOrder.cancelled) && (<>
                                                <input
                                                className="d-none"
                                                type="file" accept="image/*"
                                                ref={fileInput}
                                                onChange={event => { fileChagedHandler(event) }} />

                                            {
                                                (uploadedImage.loading) ? (
                                                    <div className={[classes.attachedReceiptWrapper,'text-center'].join(' ')}>
                                                        <Spinner/>
                                                    </div>
                                                ):(<>
                                                    <div className={classes.attachedReceiptWrapper} onClick={() => { fileInput.current.click() }}>
                                                        <img alt="receipt" title="customer_receipt" src={uploadedImage.base64} />
                                                    </div>
                                                </>)
                                            }

                                            <div className={[classes.btnAttachReceipt, (uploadedImage.selectedFile) ? 'd-block' : 'd-none'].join(' ')}>

                                                <button onClick={() => { uploadHadler() }} className="btn btn-block btn-danger">
                                                    บันทึก
                                                </button>
                                                <button onClick={() => { clearSelectedImage() }} className="btn btn-block btn-dark">
                                                    ยกเลิก
                                                </button>
                                            </div>
                                
                                </>)
                                }

                                { (purchaseOrder.hasReceipt) &&
                                (<>

                                        <div className={classes.attachedReceiptWrapper} >
                                                                                <img alt="receipt" title="customer_receipt" src={purchaseOrder.receiptImage.link} />
                                        </div>
                                    
                                </>)
                                
                                }

                                

                                <hr className={classes.sectionDivider} />
                            </div>
                        </div>
                    </div>
                    {/*  transportation */}
                    <div className="row">
                        <div className="col-12 col-lg-8 offset-lg-2">
                            <div className={classes.transportationContentWrapper}>
                                <p>ข้อมูลการขนส่งสินค้า</p>

                                <p style={{ paddingLeft: '20px' }}>
                                    จัดส่งโดย: {purchaseOrder.selectedTransportation.label}
                                </p>

                                <p style={{ paddingLeft: '20px' }}>
                                    ผู้รับ : {purchaseOrder.deliveryAddress.name}
                                </p>
                                <p style={{ paddingLeft: '20px' }}>
                                    เขต/อำเภอ : {purchaseOrder.deliveryAddress.district}
                                </p>
                                <p style={{ paddingLeft: '20px' }}>
                                    แขวง/ตำบล : {purchaseOrder.deliveryAddress.tambon}
                                </p>
                                <p style={{ paddingLeft: '20px' }}>
                                    จังหวัด : {purchaseOrder.deliveryAddress.province} 
                                </p>
                                
                                <p style={{ paddingLeft: '20px' }}>
                                    รหัสไปรษณีย์ : {purchaseOrder.deliveryAddress.zipCode}
                                </p>
                                <p style={{ paddingLeft: '20px' }}>
                                    เบอร์ติดต่อ : {purchaseOrder.deliveryAddress.telephone}
                                </p>
                                <p style={{ paddingLeft: '20px' }}>
                                    ที่อยู่ : {purchaseOrder.deliveryAddress.address}
                                </p>

                                {(purchaseOrder.hasTrasportationImages) &&(<>
                                    <p>
                                        รูปภาพแนบ
                                    </p>
                                    {purchaseOrder.transportationImages.map( transportationImage => {
                                      
                                        if(transportationImage.visible){
                                            return (<>
                                                <div className={classes.transportationImageWrapper}>
                                                        <img src={transportationImage.src} alt="" />
                                                </div>    
                                            </>);
                                        }
                                        else{
                                            return (<></>)
                                        }

                                    })}
                                    
                                </>)
                                }
                                
                                
                                <hr className={classes.sectionDivider} />
                            </div>
                        </div>
                    </div>

                    {/* billing */}
                    <div className="row">
                        <div className="col-12 col-lg-8 offset-lg-2">
                            <div className={classes.transportationContentWrapper}>
                                <p>ข้อมูลการออกใบเสร็จ</p>

                                <p style={{ paddingLeft: '20px' }}>
                                    ชื่อ-สกุล : {purchaseOrder.billingAddress.name}
                                </p>
                                <p style={{ paddingLeft: '20px' }}>
                                    เขต/อำเภอ : {purchaseOrder.billingAddress.district}
                                </p>
                                <p style={{ paddingLeft: '20px' }}>
                                   แขวง/ตำบล : {purchaseOrder.billingAddress.tambon}
                                </p>
                                <p style={{ paddingLeft: '20px' }}>
                                    จังหวัด : {purchaseOrder.billingAddress.province} 
                                </p>
                                <p style={{ paddingLeft: '20px' }}>
                                    รหัสไปรษณีย์ : {purchaseOrder.billingAddress.zipCode}
                                </p>
                                <p style={{ paddingLeft: '20px' }}>
                                    เบอร์ติดต่อ : {purchaseOrder.billingAddress.telephone}
                                </p>
                                <p style={{ paddingLeft: '20px' }}>
                                    ที่อยู่ : {purchaseOrder.billingAddress.address}
                                </p>

                                <hr className={classes.sectionDivider} />
                            </div>
                        </div>
                    </div>
                    {/* status */}
                    <div className="row">
                        <div className="col-12 col-lg-8 offset-lg-2">
                            <div className={classes.timelineContentWrapper}>
                                <p>สถานะคำสั่งซื้อ</p>
                                <div className={classes.timelineWrapper}>


                                    <table className={classes.timeline}>
                                        <tbody>

                                            {_.orderBy(purchaseOrder.statusItems, ['createdAt'], ['asc']).map((status, index) => {
                                                if (status.visible) {
                                                    return (<>
                                                        <tr>
                                                            <td className={[status.docId === 'WBai4xmNwlQf2ntUKjZh' ? 'text-danger':''].join(' ')}>
                                                                {moment(status.createdAt).format('DD/MM/YYYY HH:mm')}
                                                            </td>
                                                            <td className={[status.docId === 'WBai4xmNwlQf2ntUKjZh' ? 'text-danger':''].join(' ')}>
                                                                {status.name}
                                                            </td>
                                                        </tr>
                                                    </>);
                                                } else {
                                                    return (<></>);
                                                }

                                            })
                                            }

                                        </tbody>

                                    </table>




                                </div>
                                <hr className={classes.sectionDivider} />
                            </div>

                        </div>
                    </div>

                    {/* cancelled button */}
                    { (!purchaseOrder.cancelled) && (<>

                        <div className="row">
                        <div className="col-12 col-lg-8 offset-lg-2">
                            <div className={classes.timelineContentWrapper}>
                              
                                <div className="text-right">
                                    <button onClick={e => showConfirmModalHandler()} className="btn-danger btn ">
                                        ยกเลิกคำสั่งซื้อ
                                    </button>
                                </div>
                              

                                
                                <hr className={classes.sectionDivider} />
                            </div>

                        </div>
                    </div>
                    
                    </>) }
                    




                </div>
            </div>

        </>);
    }

    return (<>
        <ConfirmModal
            title="ยืนยันการยกเลิกคำสั่งซื้อ"
            message= {`ท่านต้องการยกเลิกคำสั่งซื้อ ใช่หรือไม่ ?` }
            show={showConfirmModal}
            confirmModal = { (confirm) => cancelPurchaseOrderHandler(confirm)}
         />
        {render}
    </>)

}

export default PurchaseOrderShow;