import React from 'react';
import Image from '../../UI/Image/Image';
import classes from './ImageOptions.module.css';




const ImageOptions = (props) => {

  const render =   props.images.map( (image,index) => {
      return (
          <div key={`image_option_div_${index}_${props.idPrefix}`} onClick={ ( e ) => props.imageOptionClicked(index)} className={[classes.ImageWrapper, props.activeIndex === index ? classes.ImageActive: '' ].join(' ')}>
                  <Image key={`image_option_image_${index}_${props.idPrefix}`} src={image.src} name={image.name} title={props.name}/>
          </div>
      );
    });

  return (
    <div className={[classes.ImageOptions].join(' ')}>

        {render}

    </div>
  )
}

export default ImageOptions;
