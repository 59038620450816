import React, { useState, useEffect } from 'react';
import BasketTiles from '../../components/BasketItems/BasketTiles';

import classes from './Basket.module.css';
import { Accordion, Card } from 'react-bootstrap';

import { useHistory } from "react-router-dom";
import {  AiOutlinePlus } from 'react-icons/ai';

// import ProductNotFound from '../../components/UI/NotFound/ProductNotFound';



import { useBasket } from '../../utility/products/BasketContext';

import { db } from '../../utility/firebase/firebaseConnection';
import { useAuth } from '../../utility/firebase/AuthContext';

import LocationItem from '../../components/Account/Locations/LocationItem/LocationItem';

import Select from 'react-select';

// import jsonTransportations from './transportations.json';

import _ from 'lodash';
import numeral from 'numeral';

import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';

import NoBasket from '../../components/UI/NotFound/NoBasket';


const Basket = (props) => {

  const key_prefix = "STfLDsYbwoaP3wdEsyMwH4Mgm5ds0BAw";
  // users
  const { currentUser } = useAuth();
  const history = useHistory();


  //  basket and order
  const { baskets } = useBasket();
  // const [orders, setOrders] = useState([]);

  // address
  const [userLocations, setUserLocations] = useState({});

  // transportation

  // const transportations = jsonTransportations.map(jsonTransportation => {
  //   return { value: jsonTransportation.index, label: jsonTransportation.name };
  // });

  const [transportations, setTransportations] = useState([]);


  const [defaultStatus, setDefaultStatus] = useState([]);


  const [activeKey, setActiveKey] = useState('0');


  const [formValidator, setFormValidator] = useState({
    order: {
      value: [],
      valid: false,
      errors: []
    },
    deliveryAddress: {
      value: {},
      valid: false,
      errors: []
    },
    billingAddress: {
      value: {},
      valid: false,
      errors: []
    },
    selectedTransportation: {
      value: {},
      valid: false,
      errors: []
    },
    formValid: false,
    formErrors: []
  });

  useEffect(() => {
    let unsubscribe;

    unsubscribe = db.collection('Transportations').onSnapshot(transportationData => {

      const transporations = transportationData.docs.map(transportation => {
        return {...transportation.data() };
      }).filter(transportation => transportation.visible );

      const sorted = _.orderBy(transporations, ['value'], ['asc']);
      setTransportations(sorted);

      const updatedFormValidator = { ...formValidator };
      const updatedSelectedTransportation = { ...updatedFormValidator.selectedTransportation };

      updatedSelectedTransportation.value = { ...sorted[0] };
      updatedFormValidator.selectedTransportation = updatedSelectedTransportation;
      // console.log('here 1',{...updatedFormValidator,updatedSelectedTransportation});
      // console.log('here 2', updatedFormValidator);
      setFormValidator({ ...updatedFormValidator });

    });

    return () => {
      unsubscribe && unsubscribe();
    }
  }, [])

  useEffect(() => {

    let unsubscribe = null;
    unsubscribe = db.collection('StatusItems').where('default', '==', true).where('visible', '==', true).onSnapshot(statusItems => {
      // console.log('statusItems', statusItems.docs.map(statusItem => statusItem.data()));
      setDefaultStatus(
        statusItems.docs.map(statusItem => {
          return ({
            docId: statusItem.id,
            ...statusItem.data()
          });
        })
      )
    });
    //const defaultStats = {name: 'ได้รับคำสั่งซื้อ / รอยืนยันการโอนเงิน', index : 0 , default: true, createdAt: new Datetime().time()};
    return () => {
      unsubscribe && unsubscribe();
    }
  }, [])

  useEffect(() => {

    let unsubscribe = null;

    if (currentUser) {
      // load location from firestore
      // uid is a keyt
      unsubscribe = db.collection('Locations').doc(currentUser.uid)
        .onSnapshot((locationData) => {
          if (locationData.exists) {

            const userLocations = { ...locationData.data() };
            setUserLocations({ ...userLocations });


            // setDeliveryAddress({
            //   selectedIndex: userLocations.defaultAddressIndex,
            //   location: { ...userLocations.locations[userLocations.defaultAddressIndex] }
            // });
            // setBillingAddress({
            //   selectedIndex: userLocations.defaultAddressIndex,
            //   location: { ...userLocations.locations[userLocations.defaultAddressIndex] }
            // });
            setFormValidator((prev) => {
              const updatedFormValidation = { ...prev };
              const deliveryAddress = updatedFormValidation.deliveryAddress;
              deliveryAddress.value = {
                selectedIndex: userLocations.defaultAddressIndex,
                location: { ...userLocations.locations[userLocations.defaultAddressIndex] }
              };
              //  need to hit confirm
              deliveryAddress.valid = false;
              deliveryAddress.errors = [];


              const billingAddress = updatedFormValidation.billingAddress;

              billingAddress.value = {
                selectedIndex: userLocations.defaultAddressIndex,
                location: { ...userLocations.locations[userLocations.defaultAddressIndex] }
              }

              //  need to hit confirm
              billingAddress.valid = false;
              billingAddress.errors = [];

              return { ...updatedFormValidation, deliveryAddress, billingAddress }

            });

          }
        });

    }

    return unsubscribe;

  }, [currentUser]);

  function basketChangedHandler() {


    const updatedFormValidation = { ...formValidator };
    const targetedObject = updatedFormValidation.order;
    targetedObject.valid = false;

    setFormValidator({ ...updatedFormValidation, targetedObject });

    // console.log('orderchecked',targetedObject);

  }

  function orderConfirmedHandler(orders) {

    // setOrders([...orders]);
    // activeKeyToggler('1');

    const errors = [];
    let checkedResult = true;

    const totalCheckedOrder = orders.filter(order => order.checked === true).length;

    const totalInValidOrders = orders.filter(order => order.valid === false && order.checked === true).length;

    if (totalCheckedOrder === 0) {
      checkedResult = checkedResult && false;
      errors.push('กรุณาเลือกสินค้าในรถเข็น  / เมื่อเลือกสินค้าในรถเข็นแล้ว ท่านจะต้องกดปุ่ม บันทึก เพื่อบันทึกรายการ');
    }
    if (totalInValidOrders !== 0) {
      checkedResult = checkedResult && false;
      errors.push('รายการสินค้าผิดพลาด กรุณาตรวจสอบจำนวนสินค้าในรถเข็น / เมื่อเลือกสินค้าในรถเข็นแล้ว ท่านจะต้องกดปุ่ม บันทึก เพื่อบันทึกรายการ');
    }

    const updatedFormValidation = { ...formValidator };
    const targetedObject = updatedFormValidation.order;

    targetedObject.value = [...orders];
    targetedObject.valid = checkedResult;
    targetedObject.errors = [...errors];

    setFormValidator({ ...updatedFormValidation, targetedObject });

    if (checkedResult) {
      activeKeyToggler('1');
    }

  }

  function deliveryAddressSelectedHandler(selectedIndex) {

    // setDeliveryAddress({
    //   selectedIndex: selectedIndex,
    //   location: { ...userLocations.locations[selectedIndex] }
    // });
    const updatedFormValidation = { ...formValidator };
    const targetedObject = updatedFormValidation.deliveryAddress;

    targetedObject.value = {
      selectedIndex: selectedIndex,
      location: { ...userLocations.locations[selectedIndex] }
    };
    targetedObject.valid = false;
    setFormValidator(
      {
        ...updatedFormValidation,
        targetedObject
      }
    )
  }

  function deliveryAddressConfirmHandler() {

    const updatedFormValidation = { ...formValidator };
    const targetedObject = updatedFormValidation.deliveryAddress;

    targetedObject.valid = true;

    setFormValidator({
      ...updatedFormValidation,
      targetedObject
    })

    activeKeyToggler('2');

  }

  function billingAddressHandler(selectedIndex) {

    // setBillingAddress({
    //   selectedIndex: selectedIndex,
    //   location: { ...userLocations.locations[selectedIndex] }
    // });

    const updatedFormValidation = { ...formValidator };
    const targetedObject = updatedFormValidation.billingAddress;

    targetedObject.value = {
      selectedIndex: selectedIndex,
      location: { ...userLocations.locations[selectedIndex] }
    };
    targetedObject.valid = false;
    setFormValidator(
      {
        ...updatedFormValidation,
        targetedObject
      }
    )


  }

  function billingAddressConfirmHandler() {

    const updatedFormValidation = { ...formValidator };
    const targetedObject = updatedFormValidation.billingAddress;

    targetedObject.valid = true;

    setFormValidator({
      ...updatedFormValidation,
      targetedObject
    })

    activeKeyToggler('4');

  }


  function activeKeyToggler(currentIndex) {

    //  collapse it
    if (activeKey === currentIndex) {
      setActiveKey('-1');
    } else {
      setActiveKey(currentIndex);
    }

  }
  function createNewAddressHandler() {
    // history.push('createLocation');
    history.push({
      pathname: 'createLocation',
      state: {
        nextTarget: 'basket',
      }
    })
  }

  function transportationSelctedHandler(option, action) {

    // setSelectedTransportation(transportations[option.value]);

    // console.log('transportationSelctedHandler',option);

    const updatedFormValidation = { ...formValidator };
    const targetedObject = { ...updatedFormValidation };
    targetedObject.selectedTransportation.value = { ...option };

    // console.log(targetedObject);
    // console.log({
    //   ...updatedFormValidation,
    //   targetedObject
    // });

    setFormValidator(
      {
        ...updatedFormValidation,
        targetedObject
      }
    )

  }

  function transportationConfirmedHandler() {

    const updatedFormValidation = { ...formValidator };
    const targetedObject = updatedFormValidation.selectedTransportation;

    targetedObject.valid = true;

    setFormValidator({
      ...updatedFormValidation,
      targetedObject
    })

    activeKeyToggler('3');

    console.log('transportationConfirmedHandler', targetedObject);

  }


  function validatePurchaseOrder() {

    const updatedFormValidation = { ...formValidator };


    const formValid = ['order', 'deliveryAddress', 'billingAddress', 'selectedTransportation'].map(key => {
      return updatedFormValidation[key].valid;
    }).reduce((prev, current) => prev && current, true);


    updatedFormValidation.formValid = formValid;
    updatedFormValidation.formErrors = (formValid) ? [] : ['เกิดข้อผิดพลาด กรุณาตรวจสอบรายการสั่งซื้อของท่านแล้วกดบันทึก เพื่อยืนยันรายการ'];

    setFormValidator(updatedFormValidation);

    (formValid) && createPurchaseOrder()
  }


  async function createPurchaseOrder() {

    // select purchased items
    const selectedItems = formValidator.order.value.filter(order => (order.checked) && (order.valid));
    // delete items from basket
    const deleteIds = selectedItems.map(selectedItem => selectedItem.basketId);

    const timeStamp = new Date().getTime();

    const purchaseOrder = {

      ID: timeStamp,
      purchaseDate: timeStamp,
      visible: true,
      cancelled: false,
      userId: currentUser.uid,
      email: currentUser.email,
      statusItems: [{ ...defaultStatus[0], createdAt: timeStamp }],
      orders: selectedItems,
      deliveryAddress: formValidator.deliveryAddress.value.location,
      billingAddress: formValidator.billingAddress.value.location,
      selectedTransportation: { ...formValidator.selectedTransportation.value, comments: null },
      transportationImages: [],

    };

    let productRefs = selectedItems.map(({ productId }) => {
      return db.collection('Products').doc(productId).get();
    });

    //  get current qty by loading product docs

    try {

      const productDocs = await Promise.all(productRefs);
      // create products
      const products = productDocs.map(productDoc => {
        return { productId: productDoc.id, ...productDoc.data() }
      });

      //  ini batch commands....
      //  for loop to create update qty query
      let batch = db.batch();
      for (const selectedItem of selectedItems) {

        const product = { ...products.filter(product => selectedItem.productId === product.productId)[0] };
        const options = [...product.options];
        const option = { ...options[selectedItem.selectedOptionIndex] };

        const newQty =  option.qty  - selectedItem.qty;
        option.qty = newQty;
       

        if (option.qty < 0 && newQty < 0) {
          throw "สินค้าไม่เพียงพอ"
        }
       
        // console.log('update qty', option);
        // console.log('selectedItem', selectedItem);

        options[selectedItem.selectedOptionIndex] = option;
        const productBatchRef = db.collection('Products').doc(product.productId);

        

        batch.update(productBatchRef, { options: options });

      }

      // create purchase order doc

      const purchaseOrderRef = db.collection("PurchaseOrders").doc();
      batch.set(purchaseOrderRef, purchaseOrder);

      // clear basket 
      for (const id of deleteIds) {
        const basketRef = db.collection("Baskets").doc(id);
        // console.log('batch clear basket 4')
        batch.delete(basketRef);
      }

      // batch.commit().catch( error => {
      //   throw "เกิดข้อผิดพลาดขึ้นระหว่างทำรายการสินค้า กรุณาตรวจสอบสัญญาณอินเตอร์เน๊ต"
      // });

      await batch.commit();
      history.push('purchaseOrders');


    }
    catch (error) {

      console.log('error happening', error);
      const updatedFormValidation = { ...formValidator };
      updatedFormValidation.formValid = false;
      updatedFormValidation.formErrors = ['เกิดข้อผิดพลาด ขณะที่กำลังบันทึกข้อมูล กรุณาลองใหม่', error];
      setFormValidator(updatedFormValidation);
    }





  }

  // console.log('basket',baskets[0].data());

  // console.log('form validator', formValidator.deliveryAddress);
  // console.log('form validator check0',formValidator.deliveryAddress.value.selectedIndex === undefined);


  let render_delivery = (<></>);
  let render_biling = (<></>);
  

  if (userLocations.hasOwnProperty('locations')) {
    render_delivery = (<>
      {
        userLocations.locations.map((userLocation, index) => {
          if (userLocation.visible === true) {
            return (
              <div className="col-12 col-md-10 offset-md-1">

                <div className={[classes.locationItemWrapper].join(' ')}>
                  <LocationItem
                    name={userLocation.name}
                    locationSelected={(selectedLocationIndex) => { deliveryAddressSelectedHandler(selectedLocationIndex) }}
                    isEdit={false}
                    isChecked={formValidator.deliveryAddress.value.selectedIndex === index}
                    locationIndex={index}
                    telephone={userLocation.telephone}
                    address={userLocation.address}
                    province={userLocation.province}
                    district={userLocation.district}
                    zipCode={userLocation.zipCode}
                    isDefaultAddress={false}
                    onUpdateDefaultIndex={(selectedLocationIndex) => { }}
                    onDeleteLocation={(selectedLocationIndex) => { }}
                  />
                </div>
              </div>
            )
          }

        })
      }
    </>);
  }



  if (userLocations.hasOwnProperty('locations')) {

    render_biling = (<>
    <div className="container">
                      <div className="row">
                        {
                          userLocations.locations.map((userLocation, index) => {

                            if (userLocation.visible === true) {
                              return (
                                <div className="col-12 col-md-10 offset-md-1">

                                  <div className={[classes.locationItemWrapper].join(' ')}>
                                    <LocationItem
                                      name={userLocation.name}
                                      locationSelected={(selectedLocationIndex) => { billingAddressHandler(selectedLocationIndex) }}
                                      isEdit={false}
                                      isChecked={formValidator.billingAddress.value.selectedIndex === index}
                                      locationIndex={index}
                                      telephone={userLocation.telephone}
                                      address={userLocation.address}
                                      province={userLocation.province}
                                      district={userLocation.district}
                                      zipCode={userLocation.zipCode}
                                      isDefaultAddress={false}
                                      onUpdateDefaultIndex={(selectedLocationIndex) => { }}
                                      onDeleteLocation={(selectedLocationIndex) => { }}
                                    />
                                  </div>
                                </div>
                              )
                            }


                          })
                        }
                      </div>
                    </div>
    

    
    </>);
  }


  // console.log('selected trans',formValidator.selectedTransportation.value);
  // console.log('treuan',transportations)

  let render = (<>
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2">
          <NoBasket />
        </div>
      </div>
    </div>
  </>);

  if (baskets.length !== 0) {

   
    render = (<>

      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">

            <Accordion activeKey={activeKey} key={`${key_prefix}_main_accordion`}>
              <Card key={`${key_prefix}_card_1`}>
                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={e => activeKeyToggler('0')} >


                  <div className="d-flex justify-content-between">
                    <div>
                      1. สินค้าในตะกร้า
                          </div>
                    <div>
                      {(formValidator.order.valid) ? <GrCheckboxSelected key={`${key_prefix}_gr_selected_1`} /> : <GrCheckbox key={`${key_prefix}_gr_checkbox_2`} />}
                    </div>
                  </div>

                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body key={`${key_prefix}_card_body_1`}>

                    <BasketTiles
                      key={`${key_prefix}_basket_tiles`}
                      basketChanged={() => basketChangedHandler()}
                      basketErrors={formValidator.order.errors}
                      basketConfirmed={(orders) => orderConfirmedHandler(orders)}
                    />

                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card key={`${key_prefix}_card_2`}>
                <Accordion.Toggle as={Card.Header} eventKey="1" onClick={e => activeKeyToggler('1')}>

                  <div className="d-flex justify-content-between">
                    <div>
                      2. ที่อยู่ในการจัดส่ง
                            </div>
                    <div>
                      {(formValidator.deliveryAddress.valid) ? <GrCheckboxSelected key={`${key_prefix}_gr_selected_3`} /> : <GrCheckbox key={`${key_prefix}_gr_selected_4`} />}
                    </div>
                  </div>

                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className={classes.addressWrapper} key={`${key_prefix}_card_body_2`}>

                    <div className="container">
                      <div className="row">

                        {render_delivery}


                      </div>
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-md-10 offset-md-1">
                          <div onClick={() => { createNewAddressHandler() }} className={[classes.newAddress, 'd-flex', 'justify-content-between', 'align-items-center'].join(' ')}>
                            <span className={classes.newAddress}>
                              เพิ่มที่อยู่ใหม่
                                            </span>

                            <AiOutlinePlus size={14} key={`${key_prefix}_ai_outline_5`} />
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="container">

                      <div className="row">

                        <div className="col-12 col-md-10 offset-md-1">

                          <div className={['text-right', classes.confirmWrapper].join(' ')}>
                            <button className={['btn', classes.btnBlack].join(' ')} onClick={() => activeKeyToggler('0')}>ย้อนกลับ</button>
                            <button className="btn btn-danger ml-2" disabled={ formValidator.deliveryAddress.value.selectedIndex === undefined  } onClick={() => deliveryAddressConfirmHandler()}>บันทึก</button>
                          </div>

                        </div>

                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card key={`${key_prefix}_card_3`}>
                <Accordion.Toggle as={Card.Header} eventKey="2" onClick={e => activeKeyToggler('2')}>


                  <div className="d-flex justify-content-between">
                    <div>
                      3. วิธีการจัดส่ง
                            </div>
                    <div>
                      {(formValidator.selectedTransportation.valid) ? <GrCheckboxSelected /> : <GrCheckbox />}
                    </div>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body key={`${key_prefix}_card_3`}>

                    <div className={[classes.transportationWrapper].join(' ')}>



                      <Select key={`${key_prefix}_transportation_selector`}
                        onChange={(option, action) => transportationSelctedHandler(option, action)}
                        maxMenuHeight={170} defaultValue={{label: "ไม่ระบุ", value: "0"}}
                        options={transportations}
                      />


                      <br />

                      <div className="text-secondary">
                        * ชำระเงินค่าขนส่งที่ปลายทาง
                            </div>

                      <div className="text-primary">
                        ** กรุณาตรวจสอบเงื่อนไขการส่งสินค้า กับบริษัทขนส่งที่ท่านเลือก เนื่องจากบริษัทขนส่งอาจไม่ดำเนินการส่งสินค้าในพื้นที่ของท่าน
                              </div>


                      <div className="text-success">
                        *** หากท่านไม่ทราบชื่อบริษัทขอส่งของท่าน ให้เลือกตัวเลือก " ไม่ระบุ " ทางบริษัทจะติดต่อกลับผ่านเบอร์โทรศัพท์ ตามที่อยู่ในการขนส่ง หรือ ที่อยู่ในการออกใบเสร็จ
                            </div>





                      <div className={[classes.confirmWrapper, 'text-right'].join(' ')}>
                        <button className={['btn', classes.btnBlack].join(' ')} onClick={() => activeKeyToggler('1')}>ย้อนกลับ</button>
                        <button className="btn btn-danger ml-2"  onClick={() => transportationConfirmedHandler()}>บันทึก</button>
                      </div>


                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>


              <Card key={`${key_prefix}_card_4`}>
                <Accordion.Toggle as={Card.Header} onClick={e => activeKeyToggler('3')}>
                  <div className="d-flex justify-content-between">
                    <div>
                      4. ที่อยู่ในการออกใบเสร็จ
                            </div>
                    <div>
                      {(formValidator.billingAddress.valid) ? <GrCheckboxSelected /> : <GrCheckbox />}
                    </div>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3" >
                  <Card.Body className={classes.billingAddressWrapper} key={`${key_prefix}_card_body_4`}>
                    {render_biling}
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-md-10 offset-md-1">
                          <div onClick={() => { createNewAddressHandler() }} className={[classes.newAddress, 'd-flex', 'justify-content-between', 'align-items-center'].join(' ')}>
                            <span className={classes.newAddress}>
                              เพิ่มที่อยู่ใหม่
                                            </span>

                            <AiOutlinePlus size={14} />
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="container">

                      <div className="row">

                        <div className="col-12 col-md-10 offset-md-1">

                          <div className={['text-right', classes.confirmWrapper].join(' ')}>
                            <button className={['btn', classes.btnBlack].join(' ')} onClick={() => activeKeyToggler('2')}>ย้อนกลับ</button>
                            <button className="btn btn-danger ml-2" disabled={ formValidator.billingAddress.value.selectedIndex === undefined  }  onClick={() => billingAddressConfirmHandler()}>บันทึก</button>
                          </div>

                        </div>

                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card key={`${key_prefix}_card_5`}>
                <Accordion.Toggle as={Card.Header} onClick={e => activeKeyToggler('4')}>
                  5. ยืนยันการสั่งซื้อ
                    </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                  <Card.Body key={`${key_prefix}_card_body_5`}>

                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-lg-6">

                          <div className={classes.summaryAddress}>
                            <h4>ที่อยู่สำหรับจัดส่ง</h4>

                            {(!formValidator.deliveryAddress.valid) ? <div className="text-danger"> *กรุณากดบันทึกที่อยู่สำหรับจัดส่ง</div> : (

                              <table>
                              
                                <tbody>
                                  <tr>
                                    <td> ชื่อ-นามสกุล </td>
                                    <td> {formValidator.deliveryAddress.value.location.name} </td>
                                  </tr>
                                  <tr>
                                    <td> เบอร์ติดต่อ </td>
                                    <td> {formValidator.deliveryAddress.value.location.telephone} </td>
                                  </tr>
                                  <tr>
                                    <td> ที่อยู่ </td>
                                    <td> {formValidator.deliveryAddress.value.location.address} </td>
                                  </tr>

                                  <tr>
                                    <td> แขวง/ตำบล </td>
                                    <td> {formValidator.deliveryAddress.value.location.tambon} </td>
                                  </tr>

                                  <tr>
                                    <td> เขต/อำเภอ </td>
                                    <td> {formValidator.deliveryAddress.value.location.district} </td>
                                  </tr>

                                  <tr>
                                    <td> จังหวัด </td>
                                    <td> {formValidator.deliveryAddress.value.location.province} </td>
                                  </tr>
                                  <tr>
                                    <td> รหัสไปรษณีย์ </td>
                                    <td> {formValidator.deliveryAddress.value.location.zipCode} </td>
                                  </tr>


                                </tbody>
                              </table>
                            )}

                          </div>

                        </div>
                        <div className="col-12 col-lg-6">



                          <div className={classes.summaryAddress}>
                            <h4>ที่อยู่สำหรับออกใบเสร็จ</h4>

                            {(!formValidator.billingAddress.valid) ? <div className="text-danger"> *กรุณากดบันทึกที่อยู่สำหรับออกใบเสร็จ</div> : (
                              <table>
                              
                                <tbody>
                                  <tr>
                                    <td> ชื่อ-สกุล </td>
                                    <td> {formValidator.billingAddress.value.location.name} </td>
                                  </tr>
                                  <tr>
                                    <td> เบอร์ </td>
                                    <td> {formValidator.billingAddress.value.location.telephone} </td>
                                  </tr>
                                  <tr>
                                    <td> ที่อยู่ </td>
                                    <td> {formValidator.billingAddress.value.location.address} </td>
                                  </tr>

                                  <tr>
                                    <td> แขวง/ตำบล </td>
                                    <td> {formValidator.billingAddress.value.location.tambon} </td>
                                  </tr>

                                  <tr>
                                    <td> เขต/อำเภอ </td>
                                    <td> {formValidator.billingAddress.value.location.district} </td>
                                  </tr>

                                  <tr>
                                    <td> จังหวัด </td>
                                    <td> {formValidator.billingAddress.value.location.province} </td>
                                  </tr>
                                  <tr>
                                    <td> รหัสไปรษณีย์ </td>
                                    <td> {formValidator.billingAddress.value.location.zipCode} </td>
                                  </tr>


                                </tbody>
                              </table>
                            )}

                          </div>

                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">

                          <div className={classes.transportationSummary}>
                            <h4>วิธีการจัดส่ง</h4>
                            {(!formValidator.selectedTransportation.valid) ? <div className="text-danger"> *กรุณากดบันทึกวิธีการขนส่ง</div> : (
                              <p>{formValidator.selectedTransportation.value.label}</p>
                            )}

                          </div>


                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">

                          <div className={classes.basketSummaryWrapper}>

                            <h4>รายการสินค้า</h4>
                            {(!formValidator.order.valid) ? (<div className="text-danger"> *กรุณากดบันทึกสินค้าในตะกร้า</div>) : (
                              <table>
                               
                                <tbody>
                                  {
                                    formValidator.order.value.map(order => {
                                      return (order.checked) && (order.valid) && (
                                        <>
                                          <tr>
                                            {/* <td className="text-left pl-2">{order.productName} <br /> {order.selectedOptionName}</td> */}
                                            <td className="text-left pl-2">{order.productName}</td>
                                            <td className="text-right pr-2">x{numeral(order.qty).format('0,00')}</td>
                                            <td className="text-right pr-2">&#3647;{numeral(order.totalPrice).format('0,00.00')}</td>
                                          </tr>
                                        </>);
                                    })}

                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colSpan="3" className="text-right pr-2">
                                      รวม&nbsp;&nbsp;&nbsp;&#3647;{numeral(formValidator.order.value.reduce((sum, order) => { return sum + _.toNumber((order.checked) ? order.totalPrice : 0); }, 0)).format('0,00.00')}
                                      </td>
                                  </tr>
                                </tfoot>
                              </table>
                            )}

                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="container">
                      <div className="row">
                        <div className="col-12">

                          {
                            formValidator.formErrors.map(error => {
                              return (
                                <p className="text-danger">{error}</p>
                              )
                            })
                          }


                        </div>
                      </div>
                    </div>

                    <div className="container">

                      <div className="row">

                        <div className="col-12 col-md-10 offset-md-1">

                          <div className={['text-right', classes.confirmWrapper].join(' ')}>
                            <button className={['btn', classes.btnBlack].join(' ')} onClick={() => activeKeyToggler('3')}>ย้อนกลับ</button>
                            <button className="btn btn-danger ml-2" onClick={() => validatePurchaseOrder()}>สั่งซื้อ</button>
                          </div>

                        </div>

                      </div>
                    </div>


                  </Card.Body>
                </Accordion.Collapse>
              </Card>

            </Accordion>




          </div>
        </div>
      </div>


    </>);
  }

  return (<>
    <div className={classes.wrapper}>
      {render}
    </div>
  </>);
}

export default Basket;
