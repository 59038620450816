
export const updateObject = (oldObject, updatedObject) => {

  return {
      ...oldObject,
      ...updateObject
  };

};



export const validator = (value, rules) => {
  let checkResult = true;

  if(!rules){
    return true;
  }

  if(rules.required){
      checkResult = checkResult &&  value.trim() !=='';
  }

  if(rules.minLength){
      checkResult = checkResult &&  value.length >= rules.minLength;
  }

  if(rules.maxLength){
      checkResult = checkResult &&  value.length <= rules.maxLength;
  }

  if(rules.pattern){
      checkResult = checkResult && rules.pattern.test(value);
  }

  if(rules.number){
      const pattern = /^\d+$/;
      checkResult = checkResult && pattern.test(value);
  }

  return checkResult;

}
