import React, { useContext, useState, useEffect } from "react"
import { db } from "../firebase/firebaseConnection";
import _ from "lodash";



const BasketContext = React.createContext();

export function useBasket() {
  return useContext(BasketContext)
}

export function BasketProvider({ children }) {

  const key_prefix = "hB65abQU40H8eU2mrur5cPQwbjpOA5al";


  const [ baskets, setBaskets ] = useState([]);
  const [ user, setUser ] = useState();

  const [basketAnimated,setBasketAnimated] = useState(false);
  const [timeoutId , setTimeoutId] = useState();
  //
  function setUserBasket(currentUser){
    // console.log('set',currentUser.email);
     setUser(currentUser);
  }

  function basketAnimationToggler(){
      setBasketAnimated(true);
      const timeoutId = setTimeout(function () {
              setBasketAnimated(false);
      }, 300);

      setTimeoutId(timeoutId);

  }

  async function addToBasket({userId,email,productId,selectedOptionIndex,qty,price,discount,totalPrice}){

      // animation state
      basketAnimationToggler();

      let productQty = parseInt(qty);

      if(! Number.isInteger(productQty)){ return;}

      const basketRef =  await db.collection('Baskets')
                          .where('productId','==', productId)
                          .where('optionIndex','==', selectedOptionIndex)
                          .where('userId','==',userId)
                          .get();

     if(!basketRef.empty){
        const productInBasketId = basketRef.docs[0].id;
        const productInBasket = basketRef.docs[0].data();
        await db.collection('Baskets').doc(productInBasketId)
                              .set({
                                  qty: productInBasket.qty + productQty,
                                  price: price,
                                  discount: discount,
                                  totalPrice:totalPrice
                                },{merge: true});

     }else{
        await db.collection('Baskets').add({
          userId:userId,
          email: email,
          productId:productId,
          optionIndex:selectedOptionIndex,
          qty:productQty,
          price:price,
          discount: discount,
          totalPrice:totalPrice,
          created_at: new Date()
        });
     }

  }

  function deleteBasket(basketId){
      db.collection("Baskets").doc(basketId).delete();
  }

  useEffect(()=>{
      // rerender
      const unsubscribe = db.collection("Baskets")
              .where('userId','==', !_.isEmpty(user) ? user.uid : 0 )
              .onSnapshot((basketData) => {
                  setBaskets(basketData.docs)
              });

      return ()=>{
        clearTimeout(timeoutId);
        (unsubscribe) && unsubscribe();
      }
  },[user]);


    const value = {
      baskets,
      setUserBasket,
      addToBasket,
      basketAnimated,
      deleteBasket
    }

    return (
      <BasketContext.Provider key={`${key_prefix}_basket_context`}  value={value}>
        { children}
      </BasketContext.Provider>
    )


}
