import React, { useState, useEffect } from 'react'
import { db } from '../../../utility/firebase/firebaseConnection';
import LocationItem from '../../../components/Account/Locations/LocationItem/LocationItem';
import FullScreenSpinner from '../../../components/Spinner/FullScreenSpinner';
import { useAuth } from '../../../utility/firebase/AuthContext';
import { useHistory } from "react-router-dom";

import classes from './Location.module.css';

import { AiOutlinePlus } from 'react-icons/ai';
import ConfirmModal from '../../../components/UI/Modal/ConfirmModal';
// import _ from 'lodash';

import no_location from '../../../assets/images/no_address_2.svg';

function Location(props) {


    const [userLocations, setUserLocations] = useState({locations:[]});
    const [loading, setLoading] = useState(false);
    const { currentUser } = useAuth();
    const history = useHistory();
    const [confirmModal, setConfirmModal] = useState({});

    useEffect(() => {

        let unsubscribe = null;
       

        if (currentUser) {
            // load location from firestore
            // uid is a keyt
            setLoading(true);
            unsubscribe = db.collection('Locations').doc(currentUser.uid)
                .onSnapshot((locationData) => {
                    // console.log('user data',locationData.data());
                    if (locationData.exists) {
                        // setUserLocations({ ...locationData.data() });
                        // setUserLocations({ ...locationData.data() });
                        const userLocations = { ...locationData.data() };
                        const filteredLocations = userLocations.locations.filter( userLocation => userLocation.visible === true );

                        setUserLocations({locations:[...filteredLocations]});

                    }else{
                        setUserLocations({locations:[]});
                    }
                  
                    setLoading(false);
                    //
                }, (error) => {
                    setLoading(false);
                },()=>{});

        }

        return unsubscribe;

    }, [currentUser]);



    function createNewAddressHandler() {
        history.push('createLocation');
    }

    function callAddressIndexConfirmModal(selectedLocationIndex, fn) {

        //  retrive selected location data
        const location = userLocations.locations[selectedLocationIndex];

        const message = (
            <>
                <p>ชื่อ-นามสกุล: {location.name} </p>
                <p>จังหวัด: {location.province} </p>
                <p>เขต / อำเภอ : {location.district} </p>
                <p>แขวง / ตำบล : {location.tambon} </p>
                <p>รหัสไปรษณีย์ : {location.zipCode} </p>
            </>
        );

        setConfirmModal({
            title: 'ท่านต้องการเปลี่ยนค่าที่อยู่นี้เป็นค่าเริ่มต้นหรือไม่',
            message: message,
            show: true,
            fired: () => { fn(selectedLocationIndex) }
        });


    }

    function callDeleteAddressConfirmModal(selectedLocationIndex, fn) {

        setConfirmModal({
            title: 'ยืนยันการลบที่อยู่',
            message: 'ท่านต้องการลบที่อยู่ใช่หรือไม่',
            show: true,
            fired: () => { fn(selectedLocationIndex) }
        });
    }


    function confirmModalHandler(isConfirmed) {


        setConfirmModal(
            {
                title: '',
                message: '',
                show: false,
                fired: null
            });

        if (isConfirmed) {
            const fn = confirmModal.fired;
            fn();
        }


    }

    function updateDefaultAddressIndexHandler(selectedLocationIndex) {

        const updatedUserLocation = { ...userLocations };
        updatedUserLocation.defaultAddressIndex = selectedLocationIndex;

        if (currentUser) {

            // console.log('send index',updatedUserLocation);

            db.collection('Locations').doc(currentUser.uid)
                .set(
                    { ...updatedUserLocation }
                ).then(response => console.log('success')).catch(error => console.error(error));

        }

    }

    function deleteLocationHandler(selectedLocationIndex) {
        const updatedUserLocation = { ...userLocations };
        const updatedLocations = updatedUserLocation.locations.map(location => ({ ...location }));
        const targetedLocation = { ...updatedLocations[selectedLocationIndex] };

        targetedLocation.visible = false;

        updatedLocations[selectedLocationIndex] = { ...targetedLocation };


        if (currentUser) {

            db.collection('Locations').doc(currentUser.uid)
                .set(
                    { ...updatedUserLocation, locations: updatedLocations }
                ).then(response => console.log('success')).catch(error => console.error(error));

        }

    }


    let render = <FullScreenSpinner />;

    // console.log('user location',userLocations)

    if (!loading) {

        if (userLocations.locations.length > 0 ) {
            render = (
                <div className="container">
                    <div className="row">
                        {
                            userLocations.locations.map((userLocation, index) => {

                                if (userLocation.visible === true) {
                                    return (
                                        <div className="col-12 col-md-10 offset-md-1">

                                            <div className={[classes.locationItemWrapper].join(' ')}>
                                                <LocationItem name={userLocation.name}
                                                    locationSelected={(selectedLocationIndex) => { }}
                                                    isEdit={true}
                                                    isChecked={false}
                                                    locationIndex={index}
                                                    telephone={userLocation.telephone}
                                                    address={userLocation.address}
                                                    province={userLocation.province}
                                                    district={userLocation.district}
                                                    zipCode={userLocation.zipCode}
                                                    isDefaultAddress={(index === userLocations.defaultAddressIndex)}
                                                    onUpdateDefaultIndex={(selectedLocationIndex) => callAddressIndexConfirmModal(selectedLocationIndex, updateDefaultAddressIndexHandler)}
                                                    onDeleteLocation={(selectedLocationIndex) => callDeleteAddressConfirmModal(selectedLocationIndex, deleteLocationHandler)}
                                                />
                                            </div>
                                        </div>
                                    )
                                }


                            })
                        }
                    </div>
                </div>


            );
        }else{

            render = (<>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8 offset-md-2">
                            <div  className="text-center my-4">
                            <img
                                style={{ width:'100%', height:'auto'}}
                                 src={no_location} 
                                 alt="no_location" 
                                 title="no_location"/>
                            </div>
                                
                        </div>
                    </div>
                </div>
                </>);

        }

      
          
           



    }


    return (
        <>
            { <ConfirmModal
                selectedStyle={{
                    'width': '400px',
                    'left': '50%',
                    'transform': 'translateX(-50%)'
                }}
                show={confirmModal.show}
                title={confirmModal.title}
                message={confirmModal.message}
                confirmModal={(isConfirmed) => { confirmModalHandler(isConfirmed) }}
            />}
            <div className={classes.wrapper}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1">
                            <h2 className={classes.header}>
                                ที่อยู่ในการจัดส่ง/ออกใบเสร็จ
                                    </h2>
                        </div>
                    </div>
                </div>
                
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-10 offset-md-1">
                            <div onClick={() => { createNewAddressHandler() }} className={[classes.newAddress, 'd-flex', 'justify-content-between', 'align-items-center'].join(' ')}>
                                <span className={classes.newAddress}>
                                    เพิ่มที่อยู่ใหม่
                                        </span>

                                <AiOutlinePlus size={14} />
                            </div>
                        </div>
                    </div>
                </div>

                {render}
                

            </div>

        </>
    )
}

export default Location;


