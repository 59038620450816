
import React, { useState, useEffect } from 'react';
import classes from './ResetPassword.module.css';

import { useAuth } from '../../../utility/firebase/AuthContext';
import { Link } from 'react-router-dom';

const ResetPassword = (props) => {

    const [errors, setErrors] = useState([]);
    const [email, setEmail] = useState();

    const { sendPasswordResetEmail } = useAuth();

    async function sendPasswordResetEmailHandler(e){
        setErrors([]);
        e.preventDefault();
        // console.log(e);
        const isEmail = ValidateEmail(email);
        console.log(email)
        if(isEmail){
                // console.log('pass')
                sendPasswordResetEmail(email).then( response =>{
                    console.log('success');
                    setErrors([
                        (<>ระบบได้ส่งอีเมลเพื่อทำการเปลี่ยนรหัสผ่านของท่านแล้ว <Link to="login">กดที่นี้เพื่อเข้าใช้งาน</Link></>)
                    ]);
                }).catch(error =>{
                    console.log(error);
                    if(error.code === 'auth/user-not-found'){
                        setErrors(['ไม่พบอีเมลของท่านในระบบ กรุณาลองใหม่']);
                    }else{
                        setErrors([error.message]);
                    }
                    
                })
        }else{
                // console.log('failed')
                setErrors(['กรุณาระบุอีเมลให้ถูกต้อง']);
        }
      
    }

    function ValidateEmail(mail) 
    {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
    {
        return (true)
    }
        
        return (false)
    }

    function emailChangeHandler(e){
        
        setEmail(e.target.value);
        console.log(e.target.value);
    }

    return (<>
        <div className={classes.wrapper}>


            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 offset-md-3">
                        <div className={classes.formWrapper}>

                            { errors.map( error => {
                              return (<>
                                    <div className="alert alert-danger mb-2">
                                        {error}
                                    </div>
                              </>);  
                            })}
                            <form onSubmit={(e) => sendPasswordResetEmailHandler(e)}  >
                                <div className="form-group">
                                    <label for="exampleInputEmail1">กรุณาระบุอีเมล</label>
                                    <input type="text" className="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        onChange={ (e)=>{ emailChangeHandler(e) }}
                                        placeholder="ตัวอย่าง racewing@gmail.com" />
                                    <small id="emailHelp" className="form-text text-muted">
                                        ทางเราจะส่งลิงก์สำหรับแก้ไขรหัสผ่านไปให้ท่านทางอีเมลที่ลงทะเบียนไว้
                                    </small>
                                </div>
                                <button type="submit" class="btn btn-danger btn-block">ยืนยัน</button>
                            </form>

                        </div>


                    </div>
                </div>
            </div>
        </div>

    </>);

}

export default ResetPassword;
