import React from 'react';
import classes from './ImageTile.module.css';
import { Link } from 'react-router-dom';
// import Image from '../../UI/Image/Image';
import ProductImage from '../../UI/ProductImage/ProductImage';
import numeral from 'numeral';

import {AiFillHeart } from 'react-icons/ai';

// import gridImage from './grid.png';

const ImageTile = (props) => {

  // console.log('userLevel',props);

  const memberLevel = ('userLevel' in props)  ? props.userLevel.level : null;
  const memberPrices = ( Array.isArray(props.memberPrices) ) ? props.memberPrices : [];
  const selectedPrice = memberPrices.filter( memberPrice => memberPrice.level === memberLevel);
  const discount = (selectedPrice.length > 0) ? selectedPrice[0].price : props.price;
  const isDiscounted = (props.price > discount) ? true : false;
  // const discount = 99;
  // console.log('member level',props.userLevel );
  // console.log(props.price,discount);

  return (
    <Link to={`/products/${props.productId}`} className={[classes.link].join(' ')}>
        <div className={[classes.tile].join(' ')}>

          <ProductImage src={props.src} alt={props.name} title={props.name} />
          <h2 className={[classes.header,classes.clamper].join(' ')}>
              {props.name}
          </h2>

          <div className="d-flex justify-content-between align-items-center">

                  <div className={[classes.price,classes.clamper].join(' ')}>
                  
                      <span className={[(isDiscounted) ? classes.priceDiscounted:''].join(' ')}>&#3647;{numeral(props.price).format('0,00.00')}</span>
                      { (isDiscounted) && (<>&nbsp;{numeral(discount).format('0,00.00')}</>)}
                  </div>

                  <div className={classes.likeIcon}>
                  {(props.like) ? <AiFillHeart/> : '' }
                  </div>
                  
          </div>
        
        </div>
    </Link>

  );

};


export default ImageTile;
