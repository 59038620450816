import React from 'react';


import noImage from '../../../assets/images/noimage1.png';

//  product image force width and height
const ProductImage = (props) => {

  // const [tile, setTile] = useState({width:0px,height:0px});
  function ImageNotFound(e){

    // console.log('not found');
    e.target.src = noImage;
    e.target.title = 'not found';
    e.target.alt = 'not found';
  }






return (
    <>
    <img 
    style={{width:'163px',height:'163px'}}
    className={props.selectedClasses} 
    onError={ImageNotFound} 
    src={props.src} 
    alt={props.name} 
    title={props.name} />
    </>
)



}


export default ProductImage;