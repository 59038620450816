import React, { useEffect, useState } from 'react';
import Sliders from '../Sliders/Sliders';
import Spinner from '../../../Spinner/Spinner';
import uuid from 'react-uuid';
import { db } from '../../../../utility/firebase/firebaseConnection';

const ProductSliders = (props) => {

  const [ images, setImages ] = useState([]);

  const [imageIndex, setImageIndex] = useState(0)

  useEffect(()=>{
//  console.log('effect triggered')
    const unsubscribe =   db.collection('Sliders')
                          .doc('main_sliders')
                          .onSnapshot((images) => {
                              setImages([...images.data().images.map( image => image)])
                          });
    return unsubscribe;
  },[]);

  function controlClickedHandler(targetIndex){
   
    setImageIndex(targetIndex)
  }



  let render = (
                    <div className="d-flex align-items-center justify-content-center" style={{height:'300px'}}>
                        <Spinner/>
                    </div>
                  );
  if( images.length > 0){
    render = (<> 
        <Sliders key="YDRedEyXfr1N9ufxphsIa1yg5D8dW5Ja" 
        fade={false} 
        interval="3000" 
        selectedOptionIndex = {imageIndex}
        controlClicked={ (targetIndex) => { controlClickedHandler(targetIndex) } }  
        images={images} /> 
    </>);
  }

 

  return (<>
              {render}
          </>);

}

export default ProductSliders;
