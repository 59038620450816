import React, { useEffect ,useState } from 'react'
import { db } from '../../utility/firebase/firebaseConnection';
import BrandItem from '../../components/BrandItem/BrandItem';
import Spinner from '../../components/Spinner/Spinner';
import classes from './Brand.module.css';

function Brands() {

    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(false);
    

    useEffect(() => {
        
        let unsubscribe;

            setLoading(true);
            unsubscribe = db.collection('Brands')
                .orderBy('name','asc')
                .onSnapshot((brandData) => {

                    const elements = brandData.docs.map(brandDoc => {
                        return ({
                            docId: brandDoc.id,
                            ...brandDoc.data()
                        })
                    }).filter(brand => brand.show === true && brand.deleted === false);
                    
                    setBrands(elements);
                   
                    setLoading(false);
                });

        

        return () => {
            setLoading(false);
            unsubscribe && unsubscribe();
        }

        
    }, []);


  
    let render;

  

    if(loading){
        // display spinner
        render = (<>
            <div className="col-12">
                <div className="text-center my-4">
                        <Spinner />
                </div>
            </div>
        </>);
    }else{
     
     
        if(brands.length !== 0){

            render = brands.map( brand => {
                        return (<>
                        <div className="col-4 col-lg-2">
                            <div className={classes.brandItemWrapper}>
                            <BrandItem name ={brand.name} 
                            brandId = {brand.docId}
                            src={brand.image.src}  />
                            </div>
                            
                        </div>
                        </>);

                    });
            

        }else{
            render = (<>
                <div className="col-12">
                    <div className="text-center my-4">
                            <h2>No Brands</h2>
                    </div>
                </div>
            </>);
        }
    }


    return (
        <>
        <div className={classes.myContainer}>

        <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className={classes.myHeader}>แบรนด์</h2>
                    </div>
                </div>
                <div className="row">

                
                            { render }
                </div>
            </div>

        </div>

           
        </>
    )
}

export default Brands
