import React from 'react';
import Spinner from './Spinner';


const FullScreenSpinner = () => {
  return (
  <div style={{height:'100vh'}} className="w-100 d-flex align-items-center justify-content-center"><Spinner/></div>
  );
}

export default FullScreenSpinner;
