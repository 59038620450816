import React from 'react';

import NoWishesImg from '../../../assets/images/no_wishes.svg';
// import NoWishesImg from '../../../assets/images/no-fav_2.svg';

const NoWishes = ( props ) => {

  //  page not found
  return (
            <img style={{ width:'100%',height:'auto'}} src={NoWishesImg} alt="no wishes" />
  );

}


export default NoWishes;
