import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import _ from 'lodash';


const UserSpendingModal = (props) => {

  return (
    <>
      <Modal show={props.showUserSpending} size="md">
        <Modal.Header>
          <Modal.Title>ระดับสมาชิก</Modal.Title>
        </Modal.Header>
        <Modal.Body>
               
                <p>
                ระดับปัจจุบัน : {props.userLevelName}<br/>
                <div className="progress">
                    <div className="progress-bar bg-info" role="progressbar" style={{'width':`${props.userHasSpentPercentage}%`}} aria-valuenow={props.userHasSpentPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                ยอดเดือนล่าสุด : <span>{props.userHasSpent} </span> บาท
                </p>
                <div class="alert alert-secondary" role="alert">
                <p className="mb-0 pb-0">ระดับ BRONZE ยอด 10,000 บาท/เดือน</p>
                <p className="mb-0 pb-0">ระดับ SILVER ยอด 50,000 บาท/เดือน</p>
                <p className="mb-0 pb-0">ระดับ GOLD ยอด 100,000 บาท/เดือน</p>
                </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.userSpendingModalClosed} variant="danger">
            Close
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );

}


export default UserSpendingModal;
