import React,{ useEffect, useState } from 'react';
import { db } from  '../../../utility/firebase/firebaseConnection';
import classes from './SideBar.module.css';
import MenuItem from './MenuItem/MenuItem';
import { useAuth } from '../../../utility/firebase/AuthContext';
import { useWish } from '../../../utility/products/WishContext';


import userIcon from '../../../assets/images/user.svg';
import aboutusIcon from '../../../assets/images/aboutus.svg';
import questionIcon from '../../../assets/images/questions.svg';
import addUserIcon from '../../../assets/images/adduser.svg';
import loginIcon from '../../../assets/images/login.svg';

//  how to make a payment icon
// import  howtopayIcon from '../../../assets/images/login.svg';

// import creditIcon from '../../../assets/images/credit.svg';
import wishIcon from '../../../assets/images/wishes.svg';
import orderIcon from '../../../assets/images/orders.svg';
import logoutIcon from '../../../assets/images/logout.svg';
import profileIcon from '../../../assets/images/profile.svg';



import { useHistory } from "react-router-dom";
import AlertModal from "../../UI/Modal/AlertModal";
import Collapse from 'react-bootstrap/Collapse';
// import uuid from 'react-uuid';



import _ from 'lodash';



const SideBar = ( props ) => {

        const { currentUser, logout } = useAuth();
        const [ groupItems, setgroupItems ] = useState([]);
        const [ error, setError] = useState();
        const history = useHistory();


        const personal_menu_key_prefix = "K1RFTMJuaA";

        // const [wishes, setWishes ] = useState(0);
        const { wishes,setUserWish } = useWish();


        const [showUserMenus,setShowUserMenus] = useState(false);

        let attachedClasses = [classes.SideDrawer, classes.Close];
        if (props.open) {
            attachedClasses = [classes.SideDrawer, classes.Open];
        }


        // load side bar menus
        useEffect(()=>{

            const unsubscribe = db.collection('Groups')
                                  .orderBy('no','asc')
                                 
                                  .onSnapshot((groupData) => {
                                    
                                    //   const groups = groupData.docs.map( doc => [doc.id, {...doc.data(), show:false }]);
                                    const groups = groupData.docs.map( doc => ({ docId: doc.id ,...doc.data(),show:false}) );
                                    // console.log(groups);

                                    const sorted = _.orderBy(groups,['name']);

                                      setgroupItems( sorted );

                                  }, error => {
                                      setError(error.message);
                                  });
           return unsubscribe;

        },[]);

        useEffect(()=>{
            setUserWish(currentUser);
        },[currentUser]);



        //  doc ID is groupId ,
        function showProductType(selectedGroupIndex){


            const updatedGroupItems = [...groupItems];

            for(let i =0 ; i < updatedGroupItems.length; i ++){

                // const groupItem = { ...updatedGroupItems[i]};
                if( i !== selectedGroupIndex){
                    updatedGroupItems[i].show = false;
                }
            }

            updatedGroupItems[selectedGroupIndex].show =  !updatedGroupItems[selectedGroupIndex].show;
            setgroupItems(updatedGroupItems);

        }

        function showProductByType(groupId,groupName,typeId,typeName){

            props.clicked();
            history.push('/productgroup',{ 
            category:{
               
                groupId: groupId,
                groupName: groupName,
                typeName: typeName,
                typeId: typeId
            }
               
            })

            // console.log({
               
            //     groupId: groupId,
            //     groupName: groupName,
            //     typeName: typeName,
            //     typeId: typeId
            // });
        }

        function menuItemClickedHandler(link){
            // console.log(link);
            // setShowUserMenus(prev => !prev);
            props.clicked();
            history.push(link);
        }

        function logoutHandler(){
            props.clicked();
          logout().then( response => {
              history.push('/');
          }).catch( error => {
              setError(error.message);
          })
        }


        // let totalWishes = wishes.reduce( (sum,wish) => wish.data().qty + sum , 0);
        let totalWishes = wishes.length;
        

        if(totalWishes === 0){
           totalWishes = '';
        }
        if(totalWishes > 99 ){
          totalWishes = '+99';
        }



        const userMenus = [];
        const render = [];
        const otherMenus = [];

        if(currentUser){

                // userMenus.push(<MenuItem
                //               key={uuid()}
                //               hasImage={true}
                //               icon={creditIcon}
                //               menuItemClicked={()=> menuItemClickedHandler("/credit")}
                //               name="บัญชีธนาคาร/บัตร" />);

                

                userMenus.push(<MenuItem
                                key={`${personal_menu_key_prefix}_1`}
                                hasImage={true}
                                icon={profileIcon}
                                menuItemClicked={()=> menuItemClickedHandler("/location")}
                                name="ข้อมูลส่วนตัว / ที่อยู่" />);

                userMenus.push(<MenuItem
                                key={`${personal_menu_key_prefix}_2`}
                                hasImage={true}
                                icon={wishIcon}
                                menuItemClicked={()=> menuItemClickedHandler("/wishes")}
                                name={ `สิ่งที่ถูกใจ <sup>${totalWishes}</sup>`} />);
                userMenus.push(  <MenuItem
                                key={`${personal_menu_key_prefix}_3`}
                                hasImage={true}
                                icon={orderIcon}
                                menuItemClicked={()=> menuItemClickedHandler("/purchaseorders")}
                                name="รายการสั่งซื้อ" />);
                userMenus.push(  <MenuItem
                                key={`${personal_menu_key_prefix}_4`}
                                hasImage={true}
                                icon={logoutIcon}
                                menuItemClicked={()=> logoutHandler()}
                                name="ออกจากระบบ" />);

        }else{
                // login
                userMenus.push(  <MenuItem
                                key={`${personal_menu_key_prefix}_5`}
                                hasImage={true}
                                icon={loginIcon}
                                menuItemClicked={()=> menuItemClickedHandler("/login")}
                                name="เข้าใช้งาน" />);


                // register
                userMenus.push(  <MenuItem
                                key={`${personal_menu_key_prefix}_6`}
                                hasImage={true}
                                icon={addUserIcon}
                                menuItemClicked={()=> menuItemClickedHandler("/register")}
                                name="ลงทะเบียน" />);
        }

        groupItems.forEach( ( groupItem , index) => {

            // console.log('groupItem',groupItem);
          render.push(
            <MenuItem key={`${personal_menu_key_prefix}_${groupItem.docId}`}
            menuItemClicked={()=> showProductType(index)}
            hasImage={false}
            show={groupItem.show}
            icon={groupItem.icon}
            hasArrow = {true}
            name={groupItem.name} />
          )

          if(_.has(groupItem,'types')){

            const typeMunuItems = groupItem.types.map( (type,index) => (
              <MenuItem
                              key={`${personal_menu_key_prefix}_${groupItem.docId}_${index}`}
                              hasImage={false}
                              menuItemClicked={()=> showProductByType(groupItem.docId,groupItem.name,type.id,type.name)}
                              name={type.name} />
            ));


            render.push(<Collapse 
                        key={`${personal_menu_key_prefix}_collapse_${index}`}
                        in={groupItem.show}>
                              <div className={ [classes.collapseList].join(' ') }>
                                  {typeMunuItems}
                              </div>
                          </Collapse>
                        );
          }
        });


        otherMenus.push(<MenuItem
            key={`${personal_menu_key_prefix}_howtoPay`}
            hasImage={true}
            icon={questionIcon}
            menuItemClicked={()=>{menuItemClickedHandler("/howtopay")}}
            name="คำถาม / การชำระเงิน" />);

        // about and q&a
        otherMenus.push(<MenuItem
                        key={`${personal_menu_key_prefix}_about`}
                        hasImage={true}
                        icon={aboutusIcon}
                        menuItemClicked={()=>{menuItemClickedHandler("/contact")}}
                        name="เกี่ยวกับเรา" />);



        // otherMenus.push(<MenuItem
        //                 key={uuid()}
        //                 hasImage={true}
        //                 icon={questionIcon}
        //                 menuItemClicked={()=>{menuItemClickedHandler("/questions")}}
        //                 name="คำถามที่พบบ่อย" />);






    return (
        <>
        <div className={attachedClasses.join(' ')}>

                  <AlertModal 
                  key={`${personal_menu_key_prefix}_sidebar_alert`}
                  message={error} 
                  alertModalClosed={()=> setError()} />

                          <div className={ [classes.sectionWrapper].join(' ')}>
                          <MenuItem
                                  key={`${personal_menu_key_prefix}_userMenu_grouper`}
                                  hasImage={true}
                                  icon={userIcon}
                                  menuItemClicked={()=> setShowUserMenus(prev => !prev) }
                                  name={(currentUser) ? currentUser.email : 'ผู้ใช้งาน' } />

                          <Collapse in={showUserMenus} key={`${personal_menu_key_prefix}_collapse`}>
                              <div className={ [classes.collapseList].join(' ') }>
                              {userMenus}
                              </div>
                          </Collapse>
                          </div>

                          <div className={ [classes.sectionWrapper].join(' ') }>
                              {render}
                          </div>
                          <div className={ [classes.sectionWrapper].join(' ') }>
                              {otherMenus}
                          </div>



        </div>
        </>
    );
};

export default SideBar;
