import React , { useEffect , useState } from 'react'
import { useParams } from "react-router-dom";
import { db } from '../../utility/firebase/firebaseConnection';
import FullScreenSpinner from '../../components/Spinner/FullScreenSpinner';
import ProductNotFound from '../../components/UI/NotFound/ProductNotFound';
import ImageTile from '../../components/ProductItem/ImageTile/ImageTile';
import { useAuth } from '../../utility/firebase/AuthContext';
import classes from './ProductBrands.module.css';

function ProductBrands() {

    const { brandId , brandName } = useParams();

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    const { currentUser } = useAuth();
    const [userLevel, setUserLevel] = useState({ level: null });
    

    useEffect(() => {

        if (currentUser) {
          //  load level to determine price, 
          db.collection('Users').doc(currentUser.uid).onSnapshot(userLevelDoc => {
    
            if (userLevelDoc.exists) {
              setUserLevel({
                level: userLevelDoc.data().level,
                ...userLevelDoc.data()
              })
            }
          });
        }
        else{
          setUserLevel({ level: null });
        }
      }, [currentUser])

    useEffect(() => {
        
        let unsubscribe;

            if( brandId && brandName ){
               
                setLoading(true);
                unsubscribe = db.collection('Products')
                    .where('brandId','==',brandId)
                    .onSnapshot((ProductData) => {
                        
                        const elements = ProductData.docs.map(ProductDoc => {
                            return ({
                                docId: ProductDoc.id,
                                ...ProductDoc.data()
                            })
                        }).filter(product => product.show === true && product.deleted === false);
                        
                        setProducts(elements);
                        setLoading(false);
                    });

            }
        
        return () => {
            setLoading(false);
            unsubscribe && unsubscribe();
        }

        
    }, [brandId , brandName]);

    

    let render = <FullScreenSpinner />;

    if (!loading) 
    {
        if(products.length){
            render= (<>
            <div className={classes.myContainer} style={{marginTop:'70px'}}>

                    <div className="container">
                        
                        <h2 style={{ fontSize: '20px', paddingLeft: '10px',paddingBottom:'20px',paddingTop:'20px' }}>{`แบรนด์ ${brandName}`}</h2>
                        
                        <div className="row">
                            
                            { products.map(product => {
                                return (<>
                                     <div className="col-6 col-md-4 col-lg-3 col-xl-2 mb-4 mx-0 px-0">
                                                <ImageTile 
                                                productId={product.docId} 
                                                discount={product.discount} 
                                                price={product.price} 
                                                src={product.image.src} 
                                                alt={product.name} 
                                                userLevel={userLevel}
                                                memberPrices={product.memberPrices}
                                                name={product.name} />
                                     </div>
                                </>)
                            })}
                        </div>
                    </div>
                    </div>
            </>);
        }else{
            render = (<>ProductNotFound</>);
        }
    }
    return (
        <>
            {render}
        </>
    )
}

export default ProductBrands
